<template>
    <div id="info" class="infoPaciente" >
        <div class="container">
            <div class="row">

                <a id="cerrar" class="abrir-cerrar btn btn-info col-11 ml-2"  @click="abrirCerrar">
                    <font-awesome-icon icon="arrow-left" /> 
                </a>
            </div>
            <div class="row ">
                <div class="mt-3 w-100">
                    <center><h5 class="text-center font-weight-bold">Historial del paciente</h5></center>
                </div>
                <div class="btn-group btn-group-toggle ml-3" data-toggle="buttons">
                    <label class="btn btn-secondary btn-sm" :class="{'active':!selector}" @click="selector=false">
                        Resultados
                    </label>
                    <label class="btn btn-secondary btn-sm px-5" :class="{'active':selector}" @click="selector=true">
                        Citas
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import sisService from '../services/sis'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faArrowLeft)
export default {
    name: 'InfoPaciente', 
    components:{
        FontAwesomeIcon
    },
    data() {
        return {
            selector: false,
            resultados:null,
            citas:null,
        }
    },
    created(){
        
    },
    methods: {
        abrirCerrar(){
            var info = document.getElementById("info");
            info.classList.toggle('mostrarInfo');
        },
        historialCitas(){
            sisService.historialCitas().then(cita => {
                this.citas=cita
            })
        },
        historialResultados(){
            sisService.historialResultados().then(cita => {
                this.citas=cita
            })
        }
    }
}
</script>
