<template>
    <div>
         <div v-if="enviado.estatus" class="alert alert-info alert-dismissible fade show m-2 mt-3" role="alert">
                            Enviado correctamente
                            <button @click="enviado=false" type="button" class="close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
        <label for="formGroupExampleInput">Envio de PDFs:</label>
                <select v-model="pdf" class="form-control" required>
                    <option disabled value="" hidden>Elegir PDF</option>
                    <option v-for="item in pdfs" :key="item.id" :value="item">{{item.nombre}}</option>
                </select>
                <button @click="enviarPDF(pdf.nombre, pdf.url)" class="btn btn-success mt-2 float-right">Enviar <i class="icon-sd-send"></i></button>
    </div>
</template>
<script>
import asesorService from '../../services/asesor'
export default {
    name: 'PDF',
    data() {
        return {
            pdfs: [
                {
                    id:1,
                    nombre:'Listado InDRE',
                    url:'https://bit.ly/SDIndreCovid'
                },
                {
                    id:2,
                    nombre:'Consentimiento y Autorización COVID-19',
                    url:'https://salud-digna.com/CONSENTIMIENTO-INFORMADO-Y-AUTORIZACI%C3%93N-SARS-CoV2-(COVID-19).pdf'
                }
            ],
            pdf:'',
            enviado:false,
        }
    },
    methods:{
        enviarPDF(nombre,url){
            asesorService.enviarPdf(nombre, url).then((resp) => { 
                this.enviado=resp
                this.$bus.$emit('bajarScroll', 1)
             })
        }
    }
}
</script>