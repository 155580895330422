let sesion = {}

function init() {
    sessionStorage.mensajeSession = JSON.stringify([])
}

function getAll() {
    return JSON.parse(sessionStorage.mensajeSession || "[]")
}

function setMensajeId(data) {
    let mensajesSesion = getAll()

    let index = isExist(data.id)
    if (index !== false) {
        mensajesSesion[index] = data
    } else {
        mensajesSesion.push(data)
    }

    sessionStorage.mensajeSession = JSON.stringify(mensajesSesion)
    return data
}

function search(id) {
    let mensajes = getAll()
    let index = isExist(id)
    if (index > -1) {
        return mensajes[index]
    }

    return false
}

function isExist(id) {
    let mensajes = getAll()
    if (id) {
        let index = mensajes.map(mensaje => mensaje.id).indexOf(id)
        return index > -1 ? index : false
    }

    return mensajes.length > 0
}

sesion.get = function(id) {
    let mensaje = search(id)
    return mensaje ? mensaje['mensaje'] : mensaje
}

sesion.set = function(id, mensaje) {
    if (!isExist()) {
        init()
    }

    return setMensajeId({ id, mensaje })
}

export default sesion