import axios from 'axios'
import config from './config'
const token = sessionStorage.getItem('token')
let apiDevoluciones = axios.create({
    
    baseURL: config.baseDevoluciones, //(network=='FB'&&ruta=='/chat')?config.baseurlFB:config.BASEURL,
    headers: {
        Authorization: `${token}`
    }
})

export default apiDevoluciones