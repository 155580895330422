<template>
    <div class="scroll">
        <br>
        <br>
        <h4>Respuestas Rapidas</h4>
        <div class="d-flex">
            
            <button class="btn btn-info btn-sm" @click="modal=true; editedItem={id:null,respuesta:null,tema:null,asesor_id:sessionStorageUser.id}; tema=null" title="Dar de alta Copy"><font-awesome-icon icon="plus"/></button>
            <v-select class="pypCombo" placeholder="Buscar por tema" :options="temas" label="nombre"  v-model="selectRespuestas" style="width:100%;padding:3px">
                <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                        No se encontró copy de: <em>{{ search }}</em>.
                    </template>
                    <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                </template>
            </v-select>
        </div>
        
        <div class="modal" :class="{'show':modal==true}" style="padding-top:260px" >
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="modalCopysLabel">{{formTitle}}</h5>
                        <button type="button" @click="modal=false" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" style="width:600px">
                        <b>Elegir tema del copy:</b><br>
                        <v-select class="pypCombo mb-3" placeholder="Tema del copy" :options="temas" label="nombre" value="id" @input="editedItem.tema=tema.id"  v-model="tema">
                            <template v-slot:no-options="{ search, searching }">
                                <template v-if="searching">
                                    No se encontró copy de: <em>{{ search }}</em>.
                                </template>
                                <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                            </template>
                        </v-select>
                        <b class="mt-3">Texto del copy:</b><br>
                        <textarea v-model="editedItem.respuesta" style="width:100%" cols="30" rows="10" maxlength="3999"></textarea>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" @click="modal=false" data-dismiss="modal">Cancelar</button>
                        <button type="button" class="btn btn-success" @click="save" :disabled="!tema||editedItem.respuesta==''">Guardar</button>
                    </div>
                </div>
            </div>
        </div>
        <li v-for="rRapida in rRapidas" :key="rRapida.id" class="respuestaRapida ml-1 row" v-show="((selectRespuestas == null) || (rRapida.tema.includes(selectRespuestas.id)))" style="width:90%">
            
            <button @click="responder('texto',rRapida.respuesta)" class="btnEnviar float-left col-1">
                <font-awesome-icon icon="share" flip="horizontal" />
            </button>
            <p class="col-11 mb-0">{{rRapida.respuesta}}</p>
            <button @click="editItem(rRapida)" class="btnEnviar float-right">
                <font-awesome-icon icon="edit"  />
            </button>
        </li>

    </div>
</template>
<script>
import asesorService from '../../services/asesor'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    faShare
} from '@fortawesome/free-solid-svg-icons'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
library.add(faShare)
export default {
    name: 'RespuestaRapida',
    components: {
        FontAwesomeIcon,
        vSelect
    },
    data() {
        return {
            rRapidas:[],
            sessionStorageUser: JSON.parse((sessionStorage.getItem('sesion'))),
            selectRespuestas:null,
            temas:[{id:'1',nombre:'Saludo'},
            {id:'2',nombre:'Despedida'},
            {id:'3',nombre:'Información general'},
            {id:'4',nombre:'Abreviatura'},
            {id:'5',nombre:'Queja'},
            {id:'6',nombre:'Paquetes'},
            {id:'7',nombre:'Lentes'},
            {id:'8',nombre:'Laboratorio'},
            {id:'9',nombre:'Mastografía'},
            {id:'10',nombre:'Ultrasonidos'},
            {id:'11',nombre:'Densitometría'},
            {id:'12',nombre:'Papanicolaou'},
            {id:'13',nombre:'Tomografía'},
            {id:'14',nombre:'Electrocardiograma'},
            {id:'15',nombre:'Rayos x'},
            {id:'16',nombre:'Resonancia'},
            {id:'17',nombre:'Nutrición'},
            {id:'18',nombre:'COVID'},
            {id:'19',nombre:'Devolución'},
            {id:'20',nombre:'Bolsa de trabajo'},
            {id:'21',nombre:'No se realiza'},
            {id:'22',nombre:'Medidas de prevención'},
            {id:'23',nombre:'Otro'},
            {id:'24',nombre:'Hemodialisis'},
            ],
            modal:false,
            selectCopy:null,
            editedIndex: -1,
            editedItem:{
                id:null,
                respuesta:null,
                tema:null
            },
            id:null,
            tema:null,
        }
    },
    created(){
        this.respuestas()
    },
    computed: {
        formTitle() {
        return this.editedIndex === -1
            ? "Alta de copy nuevo"
            : "Modificar copy";
        },
    },
    methods:{
        responder(tipo,texto) {
           if(tipo=='texto' || tipo=='enlace'){
               let sender_actual = sessionStorage.getItem('sender_actual')
               asesorService.sendMensaje(sender_actual,texto).then(() => { 
                this.$bus.$emit('bajarScroll', 1)
             })
           } 
        },
        respuestas(){
            asesorService.respuestas(this.sessionStorageUser.id).then((respuestas) => { 
                this.rRapidas = respuestas
             })
        },
        editItem (item) {

            this.editedIndex = this.rRapidas.indexOf(item)
            this.editedItem = item
            this.modal = true
            this.id=item.id
            this.tema=this.temas.filter(tema =>tema.id==item.tema)
            this.$forceUpdate
        },
        save () {
        if (this.editedIndex > -1) {
          
          asesorService.EditarRespuesta(this.editedItem).then(()=>{
            this.modal=false
            this.editedIndex= -1
            this.respuestas()
          })
        } else {
          asesorService.NuevaRespuesta(this.editedItem).then(()=>{ 
            this.modal=false
            this.editedIndex= -1
            this.respuestas()
          })
        }
        this.initialize()
      },
    }
}
</script>