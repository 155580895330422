<template>
<div class="busqueda d-flex flex-row my-2">
    <div class="container Txt gris m-1">
        <input id="busquedaChat" v-model="busqueda" @input="buscar()" class="cajaTxt gris" type="search" placeholder="Buscar por numero de paciente..." aria-expanded="false">
        <a class="btnBuscarChat gris">
            <font-awesome-icon icon="search"/>
        </a>
    </div>
    
    <div class="dropdown m-2 d-flex" id="etiqueta">
        <button v-if="asesor.rol_id<3" @click="monitorear" class="btn  border-gris" data-toggle="modal" title="Monitorear conversacion" data-target="#traspasarChat"><font-awesome-icon icon="eye"/></button>
        <button @click="traspaso" class="btn  border-gris" data-toggle="modal" title="Traspaso general" data-target="#traspasarChat"><font-awesome-icon icon="exchange-alt"/></button>
        <div v-if="asesor.grupo_id!=6">
            <!-- <button class="btn btn-secondary dropdown-toggle btnEtiqueta" type="button" id="etiqueta" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <font-awesome-icon icon="tags" :class="{'': tags==0, 'tQueja': tags==1, 'tConsulta': tags==2, 'tCovid': tags==3,'tEcommerce': tags==4, 'tTicket': tags==5, 'tRH': tags==6, 'tlucha': tags==7, 'tLentes': tags==8, 'tCallcenter': tags==9, 'tAtendido': tags==10, 'tCovidhorarios': tags==11, 'tCoviddemora': tags==12,'tSpam': tags==14,'tTConvenio': tags==15,'tPromociones': tags==16, 'tUbicacion': tags==17, 'tPreciosPreparaciones' : tags==18, 'tOtros':tags==19, 'tCitas':tags==20 }"></font-awesome-icon>
            </button> -->
            <div class="dropdown-menu" aria-labelledby="etiqueta">
                <a class="dropdown-item blanco" @click="tags=0, buscarEtiqueta()"><font-awesome-icon icon="tags" /> Todos</a>
                <a class="dropdown-item tQueja" @click="tags=1, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tQueja" /> Queja</a>
                <a class="dropdown-item tConsulta" @click="tags=2, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tConsulta" /> Resultados pendientes</a>
                <a class="dropdown-item tCovid" @click="tags=3, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tCovid" /> Estudio COVID-19</a>
                <a class="dropdown-item tEcommerce" @click="tags=4, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tEcommerce" /> Ecommerce</a>
                <a class="dropdown-item tTicket" @click="tags=5, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tTicket" /> Ticket Perdido</a>
                <a class="dropdown-item tRH" @click="tags=6, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tRH" /> Vacante</a>
                <a class="dropdown-item tlucha" @click="tags=7, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tlucha" /> Campañas</a>
                <a class="dropdown-item tLentes" @click="tags=8, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tLentes" /> Lentes</a>
                <a class="dropdown-item tCallcenter" @click="tags=9, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tCallcenter" /> Call center</a>
                <a class="dropdown-item tAtendido" @click="tags=10, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tAtendido" /> Atendido</a>
                <a class="dropdown-item tCovidhorarios" @click="tags=11, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tCovidhorarios" /> Horarios Covid</a>
                <a class="dropdown-item tCoviddemora" @click="tags=12, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tCoviddemora" /> Demora Covid</a>
                <a class="dropdown-item tcacu" @click="tags=13, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tcacu" /> Cacu 2023</a>
                <!-- <a class="dropdown-item tcacu" @click="tags=13, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tcacu" /> Cacu 2023</a> -->
                <a class="dropdown-item tSpam" @click="tags=14, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tSpam" /> Spam</a>
                <a class="dropdown-item tTConvenio" @click="tags=15, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tTConvenio" /> Ticket Convenio</a>
                <a class="dropdown-item tPromociones" @click="tags=16, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tPromociones" /> Promociones</a>
                <a class="dropdown-item tUbicacion" @click="tags=17, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tUbicacion" /> Ubicación</a>
                <a class="dropdown-item tPreciosPreparaciones" @click="tags=18, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tPreciosPreparaciones" /> Precios y Preparaciones</a>
                <a class="dropdown-item tOtros" @click="tags=19, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tOtros" /> Otros</a>
                <a class="dropdown-item tCitas" @click="tags=20, buscarEtiqueta()"><font-awesome-icon icon="tag" class="tCitas" /> No pude agendar mi cita</a>
                <!-- <div class="custom-control custom-checkbox filtros">
                    <input type="checkbox" class="custom-control-input cConsulta" id="filtroCon">
                    <label class="custom-control-label lConsulta" for="filtroCon">
                        Consulta
                    </label>

                </div>
                <div class="custom-control custom-checkbox filtros">
                    <input type="checkbox" class="custom-control-input cResultados" id="filtroRes">
                    <label class="custom-control-label lResultados" for="filtroRes">
                        Resultado
                    </label>
                </div>
                <div class="custom-control custom-checkbox filtros">
                    <input type="checkbox" class="custom-control-input cQueja" id="filtroQueja">
                    <label class="custom-control-label lQueja " for="filtroQueja">
                        Queja
                    </label>

                </div>
                <div class="custom-control custom-checkbox filtros">
                    <input type="checkbox" class="custom-control-input cUbicacion" id="filtroUbi">
                    <label class="custom-control-label lUbicacion " for="filtroUbi">
                        Ubicación
                    </label>
                </div> -->

            </div>
        </div>
    </div>
</div>

</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch, faExchangeAlt, faTags, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faSearch, faExchangeAlt, faTags, faEye)
export default {
    name: 'Buscar',
    components: {
        'font-awesome-icon': FontAwesomeIcon
    },
    data() {
        return {
            busqueda: '',
            tags:0,
            asesor: JSON.parse(sessionStorage.getItem('sesion'))
        }
    },
    methods: {
        buscar(){
            this.$bus.$emit('buscar', this.busqueda); 
        },
        traspaso(){
            this.$bus.$emit('multiple', 1);
        },
        monitorear(){
            this.$bus.$emit('monitorear', 4);
        },
        buscarEtiqueta(){
            console.log("this.taaaaaaaaaaaaaaaaaaags")
            console.log(this.tags)
            this.$bus.$emit('buscarXetiqueta', this.tags); 
            this.$bus.$emit('pedirListaChats', 1); 
        },
    }  

}
</script>
