<template>
<div class="col-md-4 col-lg-3 col-xx-2 px-0 haltura d-none d-lg-block d-xl-block">
    <div class="row">

        <div class="col-7 ml-2">
            <button v-show="sessionStorageUser.grupo_id!=8  " class="btn btn-primary w-100 " type="button" @click="pidiendoPaciente" :disabled="this.cola.cola<0" >Pedir <font-awesome-icon class="ml-1"  icon="comments"/></button>
        </div>
        <div class="col-4  pb-0 mb-0">
            <p class=" pb-0 mb-0 text-right">
                {{this.cola.cola}} <font-awesome-icon  icon="users"/> en cola. <br>
                {{this.quejas}}
                <!--i class="fas fa-user-injured"></i-->
            </p>
            <p class=" pb-0 mb-0 text-right">
                {{this.atendiendo}} <font-awesome-icon icon="user-injured"/>
            </p>
        </div>
    </div>

</div>
</template>

<script>
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/index.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUsers, faComments } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faUsers, faComments)
import Vue from 'vue'
Vue.use(VueToast)
import SocketIo from 'socket.io-client'

export default {
    name: 'Cola',
    components: {
        'font-awesome-icon': FontAwesomeIcon
    },
    methods: {
        pidiendoPaciente() {
            this.$bus.$emit('actualiza', 1);
            this.$bus.$emit('subirScrollChat', 1);
            Vue.$toast.open({
                message: 'Un momento porfavor...',
                position: 'top-left',
                type: 'default'
            });
            this.$gtag.event('pedirPaciente',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': 'Total Pedidos',
                'value': 1
            })
        },
        pacienteencola() {
            this.network=localStorage.getItem('Network')
            
            let data = {
                    query: {
                        "Tipo": "2"
                    }
                }
            if(this.network=='WA'){
                this.socket = SocketIo.connect('https://socket-ws.salud-digna.site', data)
                this.socket.on('pedirCola',  (payload) => {
                    this.cola=payload
                })
            }else if(this.network=='GOOGLE'){ 
                this.socket = SocketIo.connect('https://socket-ws.salud-digna.site', data)
                this.socket.on('pedirColaG',  (payload) => {
                    this.cola=payload
                })
            }else if(this.network=='WAni'){
                
                this.socket = SocketIo.connect('https://socket-ws.salud-digna.site', data)
                this.socket.on('pedirColaNicaragua',  (payload) => {
                    this.cola=payload
                })
            }else if(this.network=='WAhemo'){
                
                this.socket = SocketIo.connect('https://socket-ws.salud-digna.site', data)
                this.socket.on('pedirColaHemodialisis',  (payload) => {
                    this.cola=payload
                })
            }else if(this.network=='WApetct'){
                this.socket = SocketIo.connect('https://socket-ws.salud-digna.site', data)
                this.socket.on('pedirColaPetCt',  (payload) => {
                    this.cola=payload
                })
            }else if(this.network=='WAmedicos'){
                
                this.socket = SocketIo.connect('https://socket-ws.salud-digna.site', data)
                this.socket.on('pedirColaMedicos',  (payload) => {
                    this.cola=payload
                })
            }else if(this.network=='WAsa'){
                
                this.socket = SocketIo.connect('https://socket-ws.salud-digna.site', data)
                this.socket.on('pedirColaSalvador',  (payload) => {
                    this.cola=payload
                })
            }else if(this.network=='WAgml'){
                
                this.socket = SocketIo.connect('https://socket-ws.salud-digna.site', data)
                this.socket.on('pedirColaGuatemala',  (payload) => {
                    this.cola=payload
                })
            } else if(this.network=='WAmesa'){ 
                this.socket = SocketIo.connect('https://socket-ws.salud-digna.site', data)
                this.socket.on('pedirColaMesa',  (payload) => {
                    this.cola = (payload !== null) ? payload : 0;
                })
            }else {
                this.socket = SocketIo.connect('https://socket-ws.salud-digna.site', data)
                this.socket.on('pedirColaFb',  (payload) => {
                    this.cola=payload
                })
            }

        },
        pollData() {
            this.pacienteencola()
        },
        nuevoPaciente() {
            Vue.$toast.open({
                message: '________ te ha transferido un paciente.',
                position: 'bottom-left',
            });
        },
    },
    data() {
        return {
            quejas: '',
            cola: '',
            atendiendo: 0,
            polling: null,
            hiloPacienteCola: null,
            network: localStorage.getItem('Network'),
            socket:null,
            sessionStorageUser: JSON.parse(sessionStorage.getItem('sesion'))
        }
    },
    mounted() {
    },
    created() {
        this.$bus.$off('cambioNetwork')
        this.$bus.$on('cambioNetwork', () => {
            this.socket.close()
            this.cola=''
            this.pacienteencola()
            this.network= localStorage.getItem('Network')
            
        })
        
        this.$bus.$off('chatsTotal')
        this.$bus.$on('chatsTotal', i => {
            this.atendiendo=i
        })
        this.pollData()
    },
    beforeDestroy() {
    this.hiloPacienteCola = 0
    delete this.hiloPacienteCola
  },
  destroyed() {
    this.pacienteencola()
  }
}
</script>
