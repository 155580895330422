<template>
<!-- Sidebar Respuesta rapida-->
<div   id="sidebar-wrapper" class="">

    <div class="contEspecial">
        <div  class="sticky22">

 
           



            <div v-if="sender" @click="abrirPaciente" class="abrirOpc" id="menu-toggle8">
                <font-awesome-icon icon="ellipsis-v" /> <span class="tooltiptext">Paciente</span>
            </div>
            <div v-if="sender" v-show="network=='WAni'||network=='FB'||network=='WAhemo'||network=='WAsa'" @click="abrirRespusta" class="abrirCotizar"  id="menu-toggle">
                <i class="icon-search-comment"></i>&nbsp;&nbsp;
                <span class="tooltiptext">Copys</span>
            </div>
            <div v-if="sender" v-show="network=='WAsa'" @click="abrirCotizacion" class="abrirCotizar"  id="menu-toggle11">
                <font-awesome-icon icon="coins" />
                <span class="tooltiptext">Cotizaciones</span>
            </div>
            <div v-if="sender" v-show="network=='WA'" @click="abrirBot" class="abrirBot" id="menu-toggle3">
                <font-awesome-icon icon="robot" />
                <span class="tooltiptext">Bot</span>
            </div>
            <div v-if="sender" v-show="network=='WA'||network=='FB'||network=='GOOGLE' " @click="abrirRSV" class="abrirRSV" id="menu-toggle4">
                <font-awesome-icon icon="file-medical-alt" />
                <span class="tooltiptext">Resultados</span>
            </div>
            <div v-if="sender" v-show="network=='WA'||network=='FB'||network=='WAmedicos' ||network=='GOOGLE'" @click="abrirCotizacion" class="abrirCotizar" id="menu-toggle5">
                <font-awesome-icon icon="coins" />
                <span class="tooltiptext">Cotizaciones</span>
            </div>
            <div v-if="sender" v-show="network=='WA'||network=='FB' || network=='GOOGLE'" @click="abrirDevoluciones" class="abrirCotizar" id="menu-toggle10">
                <font-awesome-icon icon="hand-holding-usd" />
                <span class="tooltiptext">Devoluciones</span>
            </div>
            <div v-if="sender" v-show="network=='WA'" @click="abrirPDF" class="abrirRSV" id="menu-toggle7">
                <font-awesome-icon icon="file-pdf" />
                <span class="tooltiptext">PDF</span>
            </div>
            <div v-if="sender" v-show="network=='WA'||network=='WAmedicos'  " @click="abrirUbica" class="abrirCotizar" id="menu-toggle6">
                <font-awesome-icon icon="map-marker-alt" />
                <span class="tooltiptext">Ubica tu clinica</span>
            </div>
            <div v-if="sender" v-show="network=='WA' || network=='WApetct'||network=='WAmedicos'|| network=='GOOGLE' ||  network=='WAmesa'" @click="abrirCovid" class="abrirCotizar" id="menu-toggle9" selected>
                <i class="icon-search-comment"></i>&nbsp;&nbsp;
                <span class="tooltiptext">Respuestas rapidas</span>
            </div>

            <div v-if="sender"  @click="abrirAsignacionManual"  v-show="network=='WAmesa'"   class="abrirCotizar"  id="menu-toggle" selected>
                <i class="icon-user-1"></i>&nbsp;&nbsp;
                <span class="tooltiptext">Asignacion de numero</span>
            </div> 

            
            <div v-if="sender"  @click="abrirRespusta"  v-show="network=='WAmesa'"   class="abrirCotizar"  id="menu-toggle" selected>
                <i class="icon-search-comment"></i>&nbsp;&nbsp;
                <span class="tooltiptext">Copys</span>
            </div>

          

            <div v-if="sender" v-show="network!='WAmesa'" class="abrirCotizar" @click="abrirQuejas" id="menu-toggle12" selected>
                <font-awesome-icon icon="angry"/>&nbsp;&nbsp;
                <span class="tooltiptext">Quejas</span>
            </div>

            <div  v-show="network=='WA'" class="abrirCotizar" @click="abrirPacientesFinalizados" id="menu-toggle15" >
                 <font-awesome-icon icon="calendar"/>&nbsp;&nbsp;
                <span class="tooltiptext">Pacientes Finalizados</span>
            </div>
            
             
        </div>

        <button v-show="network !='WA' && network !='WAmesa'" v-if="sender" @click="cerrar" type="button" class="close tacha">
            <span aria-hidden="true">&times;</span>
        </button>

        <button v-show="network =='WA' || network=='WAmesa' "  @click="cerrar" type="button" class="close tacha">
            <span aria-hidden="true">&times;</span>
        </button>


        <div v-show=" (this.res == 2)" class="container Txt buscadorRespuesta fixed">
            <input v-model="buscador" class="cajaTxt transparente" placeholder="Buscar" aria-expanded="false" type="search">
            <a class=" btnBuscarChat transparente">
                <font-awesome-icon icon="search" />
            </a>
        </div>
        <!-- Respuesta Rapidas -->
        <ul v-if="this.res==1" id="rRapidas" class="ulHerramientas">

                <respuesta-rapida/>
            
        </ul>

          <!-- Respuesta Rapidas -->
        <ul v-if="this.res==16" id="asignacionNumero" class="ulHerramientas">

            <div class="scroll">
                    <div class="row">
                       <h5 style="text-align: center;font-weight: bold;">Asignacion de numero </h5> 
                       <br>
                        
                       <div  style="width:400px" class="d-flex flex-column">
                            <p  >Selecciona el asesor al que deseas Asignar numero</p>
                            <div  class="d-flex justify-center aling-center">
                                <v-select style="flex:1 0 auto" placeholder="Buscar usuario" :options="asesoresActivos" label="nombre"  v-model="destino_usuario" >
                                    <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        No se encontró un usuario llamado: <em>{{ search }}</em>.
                                    </template>
                                    <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                                    </template>
                                </v-select> 
                            </div>
                            <p class="mt-3">Introduce el numero del telefono:</p>
                            <div class="input-group input-group-sm">
                                <input v-model="buscarNumero" @keypress.enter="asignarNumero" type="text" class="form-control" placeholder="Numero de telefono" max="13" maxlength="13" aria-label="" aria-describedby="basic-addon1">
                                <div class="input-group-append">
                                    <button @click="asignarNumero" class="btn btn-info" type="button"> Asignar </button>
                                </div>
                            </div>
                        </div>
                    </div> 
            </div>

        </ul>
        <!-- Galeria -->
        <ul v-else-if="this.res==2" id="galeria" class="ulHerramientas">
 
                <li v-for="imagen in imagenes" :key="imagen.id" class="respuestaRapida ml-1 row" v-show="((buscador == '') || (rRapida.alternativa.includes(buscador)) || (rRapida.contenido.includes(buscador)))">
                
                    <button @click="responder(rRapida.tipo,rRapida.contenido,rRapida.alternativa)" class="btnEnviar float-left col-1">
                        <font-awesome-icon icon="share" flip="horizontal" />
                    </button>
                    <div class="card">
                        <img class="card-img-top imagenCardSidebar align-self-center" :src="imagen.coleccion[0].imagen" alt="Img de Card">
                        <div class="card-body"> {{imagen.tema}} </div>
                    </div>
                    
                
                </li>
            
        </ul>
        <!-- Mandar a flujos -->
        <ul v-else-if="this.res==3" id="flujos" class="ulHerramientas">

                <flujos />
            
        </ul>
        <!-- Reservaciones / Optica -->
        <ul v-else-if="this.res==4" id="rsv" class="ulHerramientas">
                <resultados />
            
        </ul>
        <!-- Cotizar -->
        <ul v-else-if="this.res==5" id="cotizaciones" class="ulHerramientas">
                <cotizaciones />
            
        </ul>
        <!-- Ubica tu clínica -->
        <ul v-else-if="this.res==6" id="ubica" class="ulHerramientas">
                <ubica />
            
        </ul>
        <!-- PDF -->
        <ul v-else-if="this.res==7" id="pdf" class="ulHerramientas">
                <pdf/>
            
        </ul>
        <!-- COVID -->
        <ul v-else-if="this.res==8" id="covid" class="ulHerramientas">
            <div class="scroll">
                    <div class="row">
                        <div class="col-12 mb-0" style="padding:1rem">
                            <v-select v-if="sessionStorageUser.grupo_id!=6 && sessionStorageUser.grupo_id!=10" class="pypCombo" placeholder="Buscar por servicio" :options="servicios" label="nombre"  v-model="servicioSeleccionadoS">
                                <template v-slot:no-options="{ search, searching }">
                                    <template v-if="searching">
                                        No se encontró copy de: <em>{{ search }}</em>.
                                    </template>
                                    <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                                </template>
                            </v-select>
                        </div>
                    </div>

            <div class="input-group mb-3 sticky bg">
                <input v-model="buscar" type="search" class="form-control" placeholder="Buscar por título" ref="inputText">
            </div>

                <li v-for="rRapida in mensajes" :key="rRapida.id" v-show="((servicioSeleccionadoS == null && buscar.trim() == '') ||
                                                                           (rRapida.servicio_nombre.includes(servicioSeleccionadoS == null ? '' : servicioSeleccionadoS.nombre) && buscar.trim() == '') ||
                                                                           (servicioSeleccionadoS == null && rRapida.nombre.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, '$1').normalize().includes(buscar)) ||
                                                                           (rRapida.servicio_nombre.includes(servicioSeleccionadoS == null ? '' : servicioSeleccionadoS.nombre) && rRapida.nombre.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, '$1').normalize().includes(buscar))
                                                                           )" class="respuestaRapida ml-1 row" >
                <!-- (servicioSeleccionadoS == null) || (rRapida.servicio_nombre.includes(servicioSeleccionadoS.nombre)) -->
                    
                    <button @click="responder(rRapida.id)" class="btnEnviar float-left col-1">
                    
                        <font-awesome-icon icon="share" flip="horizontal" />
                    </button>
                    <h5 class="font-weight-bold">{{rRapida.nombre}}</h5>
                    <!-- <span class="tooltiptext">{{rRapida.nombre}}</span> -->
                    <p class="col-11 mb-0" v-if="rRapida.tipo_msj == 'TEXT'">{{rRapida.msj}}</p>
                    <div class="card" v-else-if="rRapida.tipo_msj == 'IMG'">
                        <img class="card-img-top imagenCardSidebar align-self-center imagen" :src="rRapida.msj" alt="Img de Card">
                        <div class="card-body"> {{rRapida.caption}} </div>
                    </div>
                    <p class="col-11 mb-0" v-else>{{rRapida.msj}} <b>{{rRapida.caption}}</b></p>
                
                </li>
            </div>
            
        </ul>
        <ul v-else-if="this.res==9" id="paciente" class="ulHerramientas">
                <opciones/>
            
        </ul>
        <ul v-else-if="this.res==11" id="devolucion" class="ulHerramientas">
                <devoluciones />
            
        </ul>
        <ul v-else-if="this.res==12" id="quejas" class="ulHerramientas">
            <quejas />         
        </ul>
        <ul v-else-if="this.res==15" id="pacientesFinalizados" class="ulHerramientas">
            <pacientesFinalizados />     
        </ul>
    </div>
</div> <!-- /#sidebar-wrapper Respuesta rapida-->
</template>
<style>
.respuestaRapida:hover {
  -webkit-box-shadow: #696a6b;
  box-shadow: #696a6b;
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: ease-in; 
   background: #d4ebff;
    border-style: solid;
    border-width: 1px;
    border-color: #0074d9;}

.contEspecial .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #d4ebff;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
 
  position: absolute;
    z-index: 1;
    
    right: 105%;
}

#menu-toggle:hover .tooltiptext,#menu-toggle10:hover .tooltiptext,#menu-toggle12:hover .tooltiptext, #menu-toggle15:hover .tooltiptext, #menu-toggle8:hover .tooltiptext,#menu-toggle3:hover .tooltiptext,#menu-toggle4:hover .tooltiptext,#menu-toggle5:hover .tooltiptext,#menu-toggle6:hover .tooltiptext,#menu-toggle7:hover .tooltiptext,#menu-toggle9:hover .tooltiptext,#menu-toggle10:hover,#menu-toggle11:hover .tooltiptext {
  visibility: visible;  
}


</style>

<script>
import asesorService from '../services/asesor'
import Resultados from './Pestañas/Resultados.vue'
import Devoluciones from './Pestañas/Devoluciones.vue'
import Flujos from './Pestañas/Flujos.vue'
import Cotizaciones from './Pestañas/Cotizaciones.vue'
import RespuestaRapida from './Pestañas/RespuestaRapida.vue'
import Ubica from './Pestañas/Ubica.vue'
import PDF from './Pestañas/PDF.vue'
import Opciones from './Pestañas/Opciones.vue'
import Quejas from './Pestañas/Quejas.vue'
import PacientesFinalizados from './Pestañas/PacientesFinalizados.vue'
import VueToast from "vue-toast-notification";
import Vue from "vue";
Vue.use(VueToast);
import vSelect from 'vue-select'
//import Sars from './Pestañas/Sars.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {faCalendar, faShare, faRobot, faFile, faCoins, faMapMarkedAlt, faFileMedicalAlt, faFilePdf, faVirus,faRibbon, faHandHoldingUsd,faMailBulk } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Querys from '../graphQL/chat.graphql'
import ChatService from '../graphQL/chat.service'
library.add(faCalendar, faShare, faRobot, faFile, faCoins, faMapMarkedAlt, faFileMedicalAlt, faFilePdf, faVirus,faRibbon, faHandHoldingUsd,faMailBulk)
export default {
    name: 'HerramientasAsesor',
    components: {
        'font-awesome-icon': FontAwesomeIcon,
        'resultados': Resultados,
        'flujos': Flujos,
        'cotizaciones': Cotizaciones,
        'respuestaRapida': RespuestaRapida, 
        'ubica': Ubica,
        'pdf': PDF,
        'opciones': Opciones,
        'devoluciones': Devoluciones,
        vSelect,
        'quejas': Quejas,
        'pacientesFinalizados': PacientesFinalizados
        //'sars': Sars
    },
    data() {
        return {
            buscador: '',
            search: '',
            res: 8,
            asesoresActivos:[],
            servicios:null,
            destino_usuario :null,
            servicioSeleccionadoS:null,
            sesion: JSON.parse(sessionStorage.getItem('sesion')),
            sessionStorageUser: JSON.parse(sessionStorage.getItem('sesion')),
            network: localStorage.getItem('Network'),
            sender: false,
            imagenes: [
                {
                    tema: 'Tutorial X',
                    coleccion: [
                        {
                            imagen: 'https://www.examencei.com.mx/wp-content/uploads/2017/01/shutterstock_462376603-900x450.jpg',
                            text: 'Primer paso, darle *click* aqui'
                        },
                        {
                            imagen: 'https://lh3.googleusercontent.com/proxy/Mr2vD1pT3IqRymHa2Bmr_NY9L0WRspjlRqkTy7ULhOo5rNImebCBf1A6qvkJDbYi7rmo_I5ggysxupL2gNBQZaQ3SvHMwV1uy3LGrUF-jMyRTUY',
                            text: 'Segundo paso, darle *click* aqui'
                        },
                    ]
                },
                {
                    tema: 'Tutorial N',
                    coleccion: [
                        {
                            imagen: 'https://miposicionamientoweb.es/wp-content/uploads/2019/07/tutorial-de-Google-Search-Console-webmaster-tools.png',
                            text: 'Todo está bien'
                        },
                    ]
                },
            ],
            mensajes:null,
            mensajesLucha:null,
            buscar: '',
            buscarNumero: '521'
        }
    },
     mounted() { 
        this.asesorsActivos()
        this.getServicios()
        if(this.sessionStorageUser.grupo_id==8 ){
            this.servicioSeleccionadoS={createdAt:"2023-07-20T18:00:00.000Z",id:15,nombre:"Pet-CT",updatedAt:"2023-07-20T18:00:00.000Z"}
        }
        
    },
    created() {
       
       /* this.$bus.$off('respuestaRapida')
        this.$bus.$on('respuestaRapida', ultimoMensaje => this.buscador = ultimoMensaje)*/
        
        this.$bus.$off('actualizarNetwork-herramientas')
        this.$bus.$on('actualizarNetwork-herramientas', ()=>{
            this.network = localStorage.getItem('Network')
        })
        this.$bus.$off('chatAbierto')
        this.$bus.$on('chatAbierto', (estado)=> this.sender = estado)
        this.$bus.$on('cerrarHerramientas', () => {
            this.cerrar()
        })
        this.respuestas()
        //this.respuestasLucha()

    },
    apollo: {
        $subscribe: {}
    },
    methods: {
        getSubscriptionAndQuery() {  
                return { subscriptionName: 'getMesaActivosMesa', query: Querys.getMesaActivosMesa };
           
        
        },
        asesorsActivos(){ 
            const fechas = asesorService.getFechasHoy() 
            if(this.$apollo.subscriptions.getMesaActivos){
                    this.$apollo.subscriptions.getMesaActivos.destroy()
            }

            const { subscriptionName, query } = this.getSubscriptionAndQuery(); 
            this.$apollo.addSmartSubscription(subscriptionName, {
            query: query,
                variables () {
                    return {
                        id: this.sessionStorageUser.id,
                        fechaI: fechas.fechaI,
                        fechaF: fechas.fechaF
                    }
                },
                async result ({ data }) {
                       
                    const asesores = data.asesors
                    this.asesoresActivos=[]
                    asesores.forEach(e => {
                        if(e.login===true&&e.bitacoraasesores.length > 0)
                            e.nombre+='🔴BREAK'
                        else if(e.login=== true && e.bitacoraasesores.length == 0)
                            e.nombre+='🟢'
                        else
                            e.nombre+='🔴'
                        if(this.sesion.rol_id<=4||this.sesion.id==46){
                            this.asesoresActivos.push(e)
                        }else{
                            if(e.id==1 || e.id==3){
                                this.asesoresActivos.push(e)
                            }
                        }
                        
                    });

                    if(this.sesion.rol_id<=4||this.sesion.id==46){
                        
                        let yo ={
                            id: this.sesion.id,
                            nombre: this.sesion.nombre + '🟢'
                        }
                        let cola = {
                            id: 0,
                            nombre: 'COLA'
                        }
                        this.asesoresActivos.push(yo)
                        this.asesoresActivos.push(cola)
                    }
                },  
            })
        },
        listaAsesores() {
            asesorService.listaDeAsesores().then(respa => {
            
                if(this.sesion.rol_id<=4||this.sesion.id==46){
                let yo ={
                    id: this.sesion.id,
                    nombre: this.sesion.nombre
                }
                let cola = {
                    id: 0,
                    nombre: 'COLA'
                }
                this.asesores = respa
                this.asesores.push(yo)
                this.asesores.push(cola)
                }else{
                
                    this.asesores = respa.filter(obj=>{
                    return obj.id==10 || obj.id==21 || obj.id==25 || obj.id==82 || obj.id==47 || obj.id==46 || obj.id==58 || obj.id==86|| obj.id==97|| obj.id==85|| obj.id==113|| obj.id==99||obj.id==54||obj.id==8||obj.id==103||obj.id==48||obj.id==111
                    //return obj.id!=32 && obj.id!=16 && obj.id!=31 && obj.id!=41 && obj.id!=1 && obj.id!=4 && obj.id!=5
                    // 32: Julio, 16: Monitor, 31: Carlos Morales, 41: Alejandro Urias, 1: Axel, 4: Pedrito, 5: Hever
                    //Se quito a Victoria, y se agregó a Alejandro, Joseline, Karely, Iris y Lizbeth
                })
                }
            })
        },
        cerrar(activo){
            this.res=0
            this.$emit('emitToggled', activo)
            this.menu(this.res)
        },
        abrirRespusta() {
            this.res=1
            this.$emit('Toggled')
            this.menu(this.res)
        },
        abrirGaleria() {
            this.res=2
            this.$emit('Toggled')
            this.menu(this.res)
        },
        abrirBot() {
            this.res=3
            this.$emit('Toggled')
            this.menu(this.res)
        },
        abrirRSV() {
            this.res=4
            this.$emit('Toggled')
            this.menu(this.res)
        },
        abrirCotizacion() {
            this.res=5
            this.$emit('Toggled')
            this.menu(this.res)
        },
        abrirUbica() {
            this.res=6
            this.$emit('Toggled')
            this.menu(this.res)
        },
        abrirPDF() {
            this.res=7
            this.$emit('Toggled')
            this.menu(this.res)
        },
        abrirAsignacionManual(){
            this.res=16
            this.$emit('Toggled')
            this.menu(this.res)
        },
        abrirCovid() {
            this.res=8
            this.$emit('Toggled')
            this.menu(this.res)
            /* this.$nextTick(() => {
                this.$refs["inputText"].focus()
            }) */
            
        },
        abrirDevoluciones() {
            this.res=11
            this.$emit('Toggled')
            this.menu(this.res)
        },
        // abrirLucha() {
        //     this.res=10
        //     this.$emit('Toggled')
        // },
        abrirPaciente() {
            this.res=9
            this.$emit('Toggled')
            this.$bus.$emit('paciente')
            this.menu(this.res)
        },
        abrirQuejas() {
            this.res=12
            this.$emit('Toggled')
            this.menu(this.res)
        },
        abrirPacientesFinalizados() {
            this.res=15
            this.$emit('Toggled')
            this.menu(this.res)
        },
        
        respuestas(){
            if(this.$apollo.subscriptions.getCopys){
                this.$apollo.subscriptions.getCopys.destroy()
            }
            if(this.sessionStorageUser.grupo_id==6 || this.sessionStorageUser.grupo_id==10 || this.sessionStorageUser.grupo_id==9){
                asesorService.getTodoPorServicio().then((respuestas) => { 

                    respuestas = respuestas.filter(f => f.status ==1 )
                 this.mensajes = respuestas.sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0))
                     if(this.mensajes.tipo_msj=='IMG'){
                         respuestas.forEach((mensajes) => {
                         this.mensajes.msj = mensajes.msj + new Date().getTime();
                         })
                     }
             })
            }else{
                this.$apollo.addSmartSubscription('getCopys', {
                query: Querys.getCopys,
                async result ({ data }) {
                    const response = await ChatService.setupCopys(data.informacion_asesor)
                    this.mensajes = response.sort((a,b) => (a.nombre > b.nombre) ? 1 : ((b.nombre > a.nombre) ? -1 : 0))
                },
            })
            }
        },
        // respuestasLucha(){
        //     asesorService.laLuchaInfo().then((respuestas) => { 
        //         this.mensajesLucha = respuestas
        //      })
        // },
        async responder(id){
            if(this.network=="WApetct"){
                let sender = JSON.parse(sessionStorage.getItem('sender_actual'))
                let datos = {
                        id: id,
                        asesor_id:JSON.parse(sessionStorage.getItem('sesion')).id,
                        senderid: sender,
                }
               await asesorService.enviarCopyPet(datos)
            }
            if(this.network=="WAmedicos"){
                let sender = JSON.parse(sessionStorage.getItem('sender_actual'))
                let datos = {
                        id: id,
                        asesor_id:JSON.parse(sessionStorage.getItem('sesion')).id,
                        senderid: sender,
                }
               await asesorService.enviarCopyMed(datos)
               this.logCopy(id)
            }
            if(this.network=="WA"){
                const resp = await asesorService.covidInfoEnviar(id)
                if(resp){
                    this.logCopy(id)
                }
            } 
            if(this.network=="GOOGLE"){
                let sender = JSON.parse(sessionStorage.getItem('sender_actual'))
                let datos = {
                        id: id,
                        asesor_id:JSON.parse(sessionStorage.getItem('sesion')).id,
                        senderid: sender,
                }
               await asesorService.enviarCopyGoogle(datos)
            } 

            if(this.network=="WAmesa"){
                let sender = JSON.parse(sessionStorage.getItem('sender_actual'))
                let datos = {
                        id: id,
                        asesor_id:JSON.parse(sessionStorage.getItem('sesion')).id,
                        senderid: sender,
                }
               await asesorService.enviarCopyMesa(datos)
            } 
            
        },
        getServicios(){
            asesorService.getServicios().then(resp => {
                this.servicios = resp  
            })
        },
        logCopy(id){
            let datos = {
                copy_id: id,
                asesor_id:this.sessionStorageUser.id,
                senderid: sessionStorage.getItem('sender_actual')
            }
            asesorService.registrarLog(datos)
        },
        asignarNumero() {
            let datos = {
                "paciente_id": this.buscarNumero,
                "asesor_origen": 0,
                "asesor_traspaso": this.destino_usuario.id,
                "comentario": 1
            }
            let toast = Vue.$toast.open({
                message: "Se está asignando el número...",
                position: "bottom-right",
                type: "default",
                duration: 5000,
            });

            asesorService.asignarNumero(datos)
                .then(() => {
                    toast.close();
                    Vue.$toast.open({
                        message: "Número asignado exitosamente",
                        position: "bottom-right",
                        type: "success",
                        duration: 5000,
                    });
                })
                .catch(() => {
                    toast.close();
                    Vue.$toast.open({
                        message: "No se pudo asignar el número",
                        position: "bottom-right",
                        type: "error",
                        duration: 5000,
                    });
                });
        },
        menu(){
            let div = document.getElementById('menu-toggle')
            let div3 = document.getElementById('menu-toggle3')
            let div4 = document.getElementById('menu-toggle4')
            let div5 = document.getElementById('menu-toggle5')
            let div6 = document.getElementById('menu-toggle6')
            let div7 = document.getElementById('menu-toggle7')
            let div8 = document.getElementById('menu-toggle9')
            let div9 = document.getElementById('menu-toggle8')
            let div11 = document.getElementById('menu-toggle10')
            let div12 = document.getElementById('menu-toggle12')
            let div15 = document.getElementById('menu-toggle15')
            if(this.network=='WAni' || this.network=='WAhemo'){
                if(this.res==0){
                div.removeAttribute('style','color: #0074d9;') 
            }else{
                if(this.res==1){
                div.setAttribute('style','color: #0074d9;')
                }else{
                    div.removeAttribute('style','color: #0074d9;')
                }
            }
            }else{
                if(this.network=='WA'){
                if(this.res==0){
                div3.removeAttribute('style','color: #0074d9;')
                div4.removeAttribute('style','color: #0074d9;')
                div5.removeAttribute('style','color: #0074d9;')
                div6.removeAttribute('style','color: #0074d9;')
                div7.removeAttribute('style','color: #0074d9;')
                div8.removeAttribute('style','color: #0074d9;')
                div9.removeAttribute('style','color: #0074d9;')
                div11.removeAttribute('style','color: #0074d9;')
                div12.removeAttribute('style','color: #0074d9;')
                div15.removeAttribute('style','color: #0074d9;')
            }else{         
                if(this.res==3){
                    div3.setAttribute('style','color: #0074d9;')
                }else{
                    div3.removeAttribute('style','color: #0074d9;')
                }
                if(this.res==4){
                    div4.setAttribute('style','color: #0074d9;')
                }else{
                    div4.removeAttribute('style','color: #0074d9;')
                }
                if(this.res==5){
                    div5.setAttribute('style','color: #0074d9;')
                }else{
                    div5.removeAttribute('style','color: #0074d9;')
                }
                if(this.res==6){
                    div6.setAttribute('style','color: #0074d9;')
                }else{
                    div6.removeAttribute('style','color: #0074d9;')
                }
                if(this.res==7){
                    div7.setAttribute('style','color: #0074d9;')
                }else{
                    div7.removeAttribute('style','color: #0074d9;')
                }
                if(this.res==8){
                    div8.setAttribute('style','color: #0074d9;')
                }else{
                    div8.removeAttribute('style','color: #0074d9;')
                }
                if(this.res==9){
                    div9.setAttribute('style','color: #0074d9;')
                }else{
                    div9.removeAttribute('style','color: #0074d9;')
                }
                if(this.res==11){
                    div11.setAttribute('style','color: #0074d9;')
                }else{
                    div11.removeAttribute('style','color: #0074d9;')
                }
                if(this.res==12){
                    div12.setAttribute('style','color: #0074d9;')
                }else{
                    div12.removeAttribute('style','color: #0074d9;')
                    }
                if(this.res==15){
                    div15.setAttribute('style','color: #0074d9;')
                }else if(this.res==16){
                    div15.setAttribute('style','color: #0074d9;')
                }else{
                    div15.removeAttribute('style','color: #0074d9;')
                }
            }
            }
            if(this.network=='FB'){
                if(this.res==0){
                div.removeAttribute('style','color: #0074d9;')
                div4.removeAttribute('style','color: #0074d9;')
                div5.removeAttribute('style','color: #0074d9;')
                div11.removeAttribute('style','color: #0074d9;')
            }else{
                if(this.res==1){
                div.setAttribute('style','color: #0074d9;')
                }else{
                    div.removeAttribute('style','color: #0074d9;')
                }
                if(this.res==4){
                    div4.setAttribute('style','color: #0074d9;')
                }else{
                    div4.removeAttribute('style','color: #0074d9;')
                }
                if(this.res==5){
                    div5.setAttribute('style','color: #0074d9;')
                }else{
                    div5.removeAttribute('style','color: #0074d9;')
                }
                if(this.res==11){
                    div11.setAttribute('style','color: #0074d9;')
                }else{
                    div11.removeAttribute('style','color: #0074d9;')
                }
            }
            }
            }
            
            
        }
    }
}
</script>
