<template>
<div id="chat">
    <navbar />
    <div class="container-fluid">
        <componente-superior />
        <componente-inferior />
        <transferir-chat />
    </div>
    
</div>

</template>

<script>
import Navbar from '@/components/Navbar.vue';
import ComponenteSuperior from '@/components/ComponenteSuperior.vue'
import ComponenteInferior from '@/components/ComponenteInferior.vue'
import TransferirChat from '@/components/TransferirChat.vue'

export default {
    name: 'chat',
    components: {
        Navbar,
        ComponenteSuperior,
        ComponenteInferior,
        TransferirChat,
    },
    mounted(){ 
         this.validadUsuario();
    },
    methods: {
         validadUsuario : function(){ 
             let asesor = JSON.parse(sessionStorage.getItem('sesion'))   
             if(!asesor){
                 window.location.href ='/'
             }
        }
    }
}
</script>
