import api from './apiDevoluciones'
let service = {}

service.getCode = function(folio, fecha) {
    return api.get(`/api/valida-campos?Folio=${folio}&Fecha=${fecha}`).then(res => res.data)
}

service.getCita = function(token) {
    return api.get(`/api/obten-datos-cita?code=${token}`).then(res => res.data)
}
service.devolucion = function(datos) {
    return api.post('/api/cancelar', datos).then(res => res.data)
}
service.reagendarHorarios = function(datos) {
    return api.post('/api/reagendar/horarios', datos).then(res => res)
}
service.reagendarCita = function(datos) {
    return api.post('/api/reagendar/cita', datos).then(res => res)
}
service.reagendarInfo = function(token) {
    return api.get('/api/reagendar/informacion?code='+ token).then(res => res)
}
export default service