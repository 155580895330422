<template>
<div class="row componenteInferior">
    <cola />
    <txt :class="{'d-none': !sesion}" />
</div>
</template>

<script>
import Cola from './Cola.vue'
import Txt from './Txt.vue'
export default {
    name: 'ComponenteInferior',
    components: {
        Cola,
        Txt
    },
    data() {
        return {
            sesion: false
        };
    },
    created(){
        this.$bus.$off('sesionPX')
        this.$bus.$on('sesionPX', sesion => this.sesion = sesion)
    }
}
</script>
