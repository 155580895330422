<template>
    <div>
        <p class="text-center h6">Paciente seleccionado: <br> <b>{{sender}}</b></p>
        <ul class="list-group text-dark">
            <button v-if="sesion.grupo_id!=6 && network!='WApetct'" type="button" class="list-group-item" @click="fijarPaciente" title="Fijar Paciente"><font-awesome-icon icon="thumbtack" rotation="90" size="lg" style="margin-left: 1em;text-align: right;" /> Fijar Paciente</button>
            <button v-if="sesion.rol_id!=(3||4)" type="button" class="list-group-item" title="Abrir Nota" data-toggle="modal" data-target="#exampleModal" @click="getAsesoresConver(),getMotivosSeg()"><font-awesome-icon icon="newspaper" /> Abrir Comentario</button>
            <button v-if="(sesion.id==(25)||sesion.id==(87)) && network=='WA'" type="button" class="list-group-item" @click="queja('*fecha de nacimiento*?')" title="Enviar texto: Hola, buen día, dándole seguimiento a tu caso, ¿Me podrías indicar nombre completo, clínica a la que acudió, número telefónico, estudio(s) que se hizo, fecha de nacimiento?."><font-awesome-icon icon="question-circle" /> Solicitar Datos</button>
            <button v-if="(sesion.id==(25)||sesion.id==(87)) && network=='WA'" type="button" class="list-group-item" @click="queja('y el motivo de su *queja*?')" title="Enviar texto: Hola, buen día, dándole seguimiento a tu caso, ¿Me podrías indicar nombre completo, clínica a la que acudió, número telefónico, estudio(s) que se hizo, y el motivo de su queja?."><font-awesome-icon icon="exclamation-circle" /> Solicitar datos (Queja)</button>
            <button v-if="(sesion.id==(25)||sesion.id==(87)||sesion.id==(3)) && network=='WA'" type="button" class="list-group-item" @click="repeticion()" title="Enviar texto: Nuestro equipo Salud Digna está tratando de ponerse en contacto contigo, debemos brindarte información acerca de tu estudio realizado. Te pedimos ponerte en contacto con nosotros llamando al..."><font-awesome-icon icon="phone" /> Repetición</button>
            <button v-if="sesion.grupo_id!=6 && network=='WA'" type="button" class="list-group-item" data-toggle="modal" data-target="#bloqueo" aria-controls="bloqueo" id="headingOne" title="Bloquea el numero"><font-awesome-icon icon="user-lock" /> Bloquear PX</button>
            <div id="bloqueo" class="collapse card" role="tabpanel" aria-labelledby="headingOne">
                <div class="card-body">
                    <v-select placeholder="Motivo" :options="motivos" v-model="motivo">
                        <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                                No se encontró el motivo de: <em>{{ search }}</em>.
                            </template>
                            <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                        </template>
                    </v-select>
                    <button  type="button" class="btn btn-sm btn-danger mt-2" @click="bloquear()" :disabled="motivo==null" data-dismiss="modal" > Bloquear numero</button>
                </div>
            </div>
            <button v-if="sender" type="button" class="list-group-item" @click="traspaso" data-toggle="modal" data-target="#traspasarChat" title="Traspasa este numero a un asesor"><i class="icon-chat-1" ></i> Traspasar</button>
            <button v-else type="button" class="list-group-item" @click="traspaso" title="Traspasa este numero a un asesor"><i class="icon-chat-1" ></i> Traspasar</button>
            <button type="button" class="list-group-item" @click="finalizarChat"><i class="icon-ergonomicos" title="Finaliza éste chat"></i> Finalizar Chat</button>
            <button v-if="sesion.grupo_id!=6 && network!='WApetct'" type="button" class="list-group-item" @click="despedidaProgramada" title="Pregunta al paciente si se resolvieron sus dudas, en caso contrario volverá al Inbox"><font-awesome-icon icon="user-slash" /> Paciente Atendido</button>
            <button v-if="sesion.grupo_id!=6 && network!='WApetct'" type="button" class="list-group-item" @click="finalizarChatE" title="Finaliza al paciente con la encuesta de satisfacción"><i class="icon-cursos"></i> Finalizar con Encuesta</button>
        </ul>
        <!--  Modal Notas -->
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" role="dialog" data-backdrop="static"  >
  <div class="modal-dialog modal-dialog-centered " style="top:190px">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Notas</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
            <div class="col-sm">
              <h5 style="margin-top: 5px;">Seleccione un Asesor: </h5>

                <select class="custom-select" v-model="asesorSeleccionado" style="max-width: 465px;margin-left: 5px">
                  <option v-for="selec in seleccionado" v-bind:key="selec.asesor_id" :value="selec.asesor_id">
                    {{selec.nombre}}
                  </option>
                </select>
            </div>
            </div>
        <div class="row">
            <div class="col-sm">
              <h5 style="margin-top: 5px;">Seleccione un Motivo: </h5>

                <select class="custom-select" v-model="motivoSegSeleccionado" style="max-width: 465px;margin-left: 5px">
                  <option v-for="motivo in motivosSeg" v-bind:key="motivo.id" :value="motivo">
                    {{motivo.descripcion}} 
                  </option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="input-group-prepend col-sm" style="margin-top:10px">
                <span class="input-group-text" >Descripcion</span>
                <textarea v-model="seleccionado.descripcion" type="text" class="form-control" rows="3" cols="40" maxlength="500" ></textarea>
            </div>
        </div>
        <div class="row">
            <div class="input-group-prepend col-sm" style="margin-top:10px">
                <span class="input-group-text" >Calificacion</span>
                <textarea v-model="value" type="text" class="form-control" rows="1" cols="40" maxlength="3" ></textarea>
            </div>
        </div>       
      </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                    <button v-if="asesorSeleccionado==null||motivoSegSeleccionado==null||seleccionado.descripcion==''||value==''" type="button" class="btn btn-primary" disabled >Guardar</button>
                    <button v-else type="button" class="btn btn-primary" @click="crearNota(asesorSeleccionado,motivoSegSeleccionado,seleccionado.descripcion,value)">Guardar</button>
                </div>
            </div>
        </div>
    </div>
    <!-- termian modal notas -->
</div>
</template>
<style>
#form {
  width: 250px;
  margin: 0 auto;
  height: 50px;
}

#form p {
  text-align: center;
}

#form label {
  font-size: 20px;
}

input[type="radio"] {
  display: none;
}

label {
  color: grey;
}

.clasificacion {
  direction: rtl;
  unicode-bidi: bidi-override;
}

label:hover,
label:hover ~ label {
  color: orange;
}

input[type="radio"]:checked ~ label {
  color: orange;
}
</style>
<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import asesorService from '../../services/asesor'
import mensaje from "../../services/mensaje"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelopeOpenText, faQuestionCircle, faExclamationCircle, faPhone, faUserLock,faNewspaper } from '@fortawesome/free-solid-svg-icons' 
library.add(faEnvelopeOpenText, faQuestionCircle, faExclamationCircle, faPhone, faUserLock,faNewspaper)
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/index.css";
import Vue from "vue";
//import SocketIo from 'socket.io-client'
Vue.use(VueToast);
export default {
    name:'OpcionesInbox',
    data(){
        return {
            sender:sessionStorage.getItem('sender_actual'),
            sesion:JSON.parse(sessionStorage.getItem('sesion')),
            motivo:null,
            motivos:['Solicitud de Paciente','Imagenes/Videos inapropiados','Conversación con groserías','PX envia archivos sin motivo'],
            seleccionado:{},
            asesorSeleccionado:null,
            motivoSegSeleccionado:null,
            motivosSeg:null,
            value:'',
            network:'',
            sucursales: require('../../assets/json/sucursales.json'),
            estudios:require('../../assets/json/estudios_lista.json'),
        }
    },
    components:{
        FontAwesomeIcon,
        vSelect
    },
    async created(){
        this.$bus.$off('paciente')
        this.$bus.$on('paciente', ()=> this.sender = sessionStorage.getItem('sender_actual'))
        this.network = await asesorService.getNetwork()
    },
    methods: {
        finalizarChat() {
            if(!this.sender){
                Vue.$toast.open({
                    message: 'Para usar esta funcion se requiere seleccionar un chat primero',
                    position: "top-right",
                    type: "warning",
                    duration: 6000,
                });
                return
            }
            this.$bus.$emit('actualizar-pacientes')
            this.$gtag.event('botón finalizarChatML',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': this.sender,
                'value': 1
            })
        },
        finalizarChatE() {
            if(!this.sender){
                Vue.$toast.open({
                    message: 'Para usar esta funcion se requiere seleccionar un chat primero',
                    position: "top-right",
                    type: "warning",
                    duration: 6000,
                });
                return
            }
            this.$bus.$emit('finalizar-pacientes')
            this.$gtag.event('botón finalizarEncuestaML',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': this.sender,
                'value': 1
            })
        },
        fijarPaciente(){
            if(!this.sender){
                Vue.$toast.open({
                    message: 'Para usar esta funcion se requiere seleccionar un chat primero',
                    position: "top-right",
                    type: "warning",
                    duration: 6000,
                });
                return
            }
            this.$bus.$emit('fijar-pacientes')
            this.$gtag.event('botón fijarPaciente',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': this.sender,
                'value': 1
            })
        },
        despedidaProgramada() {
            if(!this.sender){
                Vue.$toast.open({
                    message: 'Para usar esta funcion se requiere seleccionar un chat primero',
                    position: "top-right",
                    type: "warning",
                    duration: 6000,
                });
                return
            }
            this.$bus.$emit('despedida-programada')
            this.$gtag.event('botón pacienteAtendidoML',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': this.sender,
                'value': 1
            })
        },
        traspaso(){
            if(!this.sender){
                Vue.$toast.open({
                    message: 'Para usar esta funcion se requiere seleccionar un chat primero',
                    position: "top-right",
                    type: "warning",
                    duration: 6000,
                });
                return
            }
            this.$bus.$emit('sencillo', 1)
            this.$gtag.event('botón click_traspasoML',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': this.sender,
                'value': 1
            })
        },
        repeticion(){
            this.chat = false
            mensaje.envio(this.sender, "queja_reactivar_sesion_repeticion", ["6676890627"]).then(hsm=>{
                this.chat = hsm.status
            })
            this.$gtag.event('botón envioHSMqueja',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': this.sender,
                'value': 1
            })
        },
        queja(parametro){
            this.chat = false
            mensaje.envio(this.sender, "queja_reactivar_sesion_0", [parametro]).then(hsm=>{
                this.chat = hsm.status
            })
            this.$gtag.event('botón envioHSMsesion',{
                'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                'event_label': this.sender,
                'value': 1
            })
        },
        bloquear(){
            if(!this.sender){
                Vue.$toast.open({
                    message: 'Para usar esta funcion se requiere seleccionar un chat primero',
                    position: "top-right",
                    type: "warning",
                    duration: 6000,
                });
                return
            }
            let bloqueo = {
                capturista_id: JSON.parse(sessionStorage.getItem('sesion')).id,
                comentario: this.motivo,
                origen:1,
                senderid:this.sender,
                telefono:this.sender,
                status:1
            }
            asesorService.bloquearPx(bloqueo).then((respuesta)=>{
                if(respuesta.status){
                    Vue.$toast.open({
                    message: 'Se ha bloqueado el número '+ this.sender,
                    position: "top-right",
                    type: "success",
                    duration: 6000,
                    });
                    this.$gtag.event('botón bloqueaPX',{
                        'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                        'event_label': this.sender,
                        'value': 1
                    })
                }else{
                    Vue.$toast.open({
                    message: 'No fué posible bloquear el número '+ this.sender,
                    position: "top-right",
                    type: "error",
                    duration: 6000,
                    });
                }
            })
        },
        getAsesoresConver(){
            let senderid= this.sender
            asesorService.getAsesoresConver(senderid).then(resp=>{
                resp.forEach(e => {
                e
              });
              this.seleccionado= resp
            })
        },
        getMotivosSeg(){
            asesorService.getMotivosSeguimiento().then(resp=>{
                resp.forEach(e => {
                e
              });
              this.motivosSeg= resp
            })
        },
         crearNota(a,b,c,d){
            let senderid= this.sender
            let data={
                "motivo_id":b.id,
                "asesor_id":a,
                "monitor_id":this.sesion.id, 
                "telefono": senderid,
                "calificacion":d,
                "descripcion":c,
                "estatus":1
            }
            asesorService.crearNota(data)
            alert('Se creó la nota.');
            //this.darDeAlta(a,b)
            this.cerrarModal()
            this.seleccionado={}
            this.asesorSeleccionado=null
            this.motivoSegSeleccionado=null
            this.motivosSeg=null
            this.value=0
        },
        cerrarModal() {
        document.querySelector('[data-target="#exampleModal"]').click(close)
        }
        /* darDeAlta(a,b){
            let link = ''
            if(this.categoria==5){
                if(this.enlace.indexOf('https://')==-1){
                link='https://'+this.enlace
                }else{
                link=this.enlace
                }
            }
            this.socket = SocketIo.connect('https://socket-ws.salud-digna.site')
                this.socket.emit("sendNotificacion", {
                    nombre: "Comentario Plataforma",
                    descripcion: b.descripcion,
                    enlace: link,
                    tipo: 0,
                    categoria: parseInt(1),
                    fecha_inicio: this.fecha_inicio,
                    fecha_fin: this.fecha_final,
                    remitente_nombre: this.sesion.nombre,
                    remitente_asesorid: this.sesion.id,
                    destino_tipo: parseInt(3),
                    destino_asesorid: parseInt(a),
                    destino_grupoid: (parseInt(this.destino)==2?a:null),
                })
            //this.notificar(('Se envio Notificacion'), this.titulo)
            //this.ponerDefault()
        } */
    }

}
</script>