<template>
    <div class="scroll">
        <center><h5 class="mb-2">Cancelaciones/Devoluciones</h5></center>
        <div v-show="alerta!=0" class="alert alert-info alert-dismissible fade show m-2 mt-3 p-2 py-3" :class="{'alert-danger': alerta==1}" role="alert">
            {{alerta==2?'Devolución realizada correctamente':alerta==3?'Cita cancelada correctamente':'Cita no encontrada'}}
            <button @click="alerta=0" type="button" class="close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
      <div v-if="pagina==1">
        
        <input v-model="folio"  type="search" class="form-control mb-3" placeholder="Folio de cita" :disabled="status">
        <label for="theDate">Fecha de nacimiento del paciente:</label>
        <input v-model="fecha" type="date" id="theDate" class="form-control"/>


        <button v-show="fecha!=null"  @click="pedirToken" class="btn btn-success btn-sm float-right mt-3" :disabled="status">Validar <span v-if="status" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span><font-awesome-icon v-else icon="chevron-right" class="ml-1"></font-awesome-icon></button>
      </div>
      <div v-else-if="pagina==2">
        <b>Nombre:</b> <span>{{devolucion.nombrePaciente}}</span><br>
        <b>Clínica:</b> <span>{{devolucion.nombreSucursal}}</span><br>
        <b>Correo electrónico:</b> <span>{{devolucion.correo}}</span><br>
        <ul><b>{{devolucion.estudios.length==1?'Estudio:':'Estudios:'}}</b>
          <li v-for="estudio in devolucion.estudios" :key="estudio.idCitaSisPrev">
            <span class="text-uppercase">-{{estudio.paquete==false?estudio.estudio:estudio.nombrePaquete}}</span> <br>
            <span><b>Fecha y hora:</b> {{estudio.paquete==false?(estudio.fechaCita.substr(0,10)+' '+estudio.horaCita):estudio.paquete[0].fechaCita.substr(0,10)+' '+estudio.paquete[0].horaCita}}</span>
          </li>
        </ul>
        <span class="my-3"><b>Precio:</b> ${{devolucion.total}}</span><br>

        
        <v-select v-if="!devolucion.cancelada" v-show="devolucion.tipoError==0" class="mt-3" placeholder="Motivo de devolución" v-model="motivo" :options="motivos" >
            <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                    No se encontró el motivo: <em>{{ search }}</em>.
                </template>
                <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
            </template>
        </v-select>
        <div v-show="devolucion.cancelada" class="alert fade show mt-4 p-2 mb-1 alert-warning" role="alert" >
            Esta cita ya tiene su folio de Devolución: <b>{{devolucion.folioDevolucion}}</b>
        </div>
        <button @click="reagendarInfo()" class="btn btn-info btn-block btn-sm mt-3 mb-3">Reagendar Cita</button>
        <button v-if="devolucion.tipoError==1" @click="cancelarCita" class="btn btn-warning btn-sm float-right mt-5 mb-3 btnCancelarcita">Cancelar cita</button>
        <button v-show="motivo!=null" v-if="devolucion.tipoError==0" @click="pedirReembolso" class="btn btn-success btn-sm float-right mt-3 mb-3" :class="{'btn-warning': devolucion.cancelada}" :disabled="status || devolucion.cancelada">{{!devolucion.cancelada?`Devolver $${devolucion.total}`:'Devuelta'}}<span v-if="status" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span><font-awesome-icon v-else v-show="!devolucion.cancelada" icon="chevron-right" class="ml-1"></font-awesome-icon></button>
        <div v-else class="alert fade show mt-4 p-2 mb-5" role="alert" :class="{'alert-danger': devolucion.tipoError==3, 'alert-info': devolucion.tipoError==1, 'alert-warning': devolucion.tipoError==2}">
            {{devolucion.tipoError==1?'La cita no fue pagada con tarjeta':devolucion.tipoError==2?'La cita ya fue efectiva':devolucion.tipoError==4?'Oxxo PAY':devolucion.tipoError==5?'Coppel PAY':'La cita ya fue cancelada'}}
        </div>
        <button v-if="devolucion.tipoError==4" @click="copiar" class="btn btn-info mt-2 float-right">
                <font-awesome-icon icon="copy" />
        </button>
        <button @click="pagina=1" class="btn btn-danger btn-sm mt-3 mb-3">Atras</button>
      </div>
      <div class="container" v-else-if="pagina==3">
        <div class="row">
					<div class="d-flex flex-row col-12" v-for="(estudio, i) in infoReagendar.estudios" :key="i" v-show="estudio.estatusCita==1">
						<!-- <div class="colorEstudio violeta" :style="'background-color:'+ estudio.colorEstudio">
							<div class="circulo">
							</div>
						</div> -->
						<div class="datos d-flex flex-column">
							<b>{{estudio.subEstudioVal || estudio.nombreEstudio}}</b>

							<p><b>Sucursal:</b> <span class="capi">{{ devolucion.nombreSucursal }}</span></p>

							<div class="d-flex flex-column w-100">
								<div class="d-flex flex-column mr-3">
								<b>Fecha</b> 
                <div style="position:relative">
                  <Calendar
                    v-model="date[i]"
										@date-select="consultarHorarios(infoReagendar.estudios[i], i)"
										no-title
										:minDate="dateplaceholder"
										:maxDate="estudio.covid?fechaCovid._d:maxDate"
                    :manualInput="false"
                    :locale="es"
                    dateFormat="dd/mm/yy"
                    :showIcon="true"
                     />
                </div>
								<!-- <v-menu
									v-model="menu[i]"
									:close-on-content-click="false"
									:nudge-right="40"
									transition="scale-transition"
									offset-y
									min-width="290px"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="date[i]"
											hint="Introducir fecha valida"
											readonly
											outlined
											dense
											:placeholder="dateplaceholder"
											
											v-bind="attrs"
											v-on="on"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="date[i]"
										@input="consultarHorarios(infoReagendar.estudios[i], i), menu[i] = false"
										no-title
										:min="dateplaceholder"
										:max="estudio.covid?fechaLimite:null"
										:allowed-dates="null"
										scrollable
									></v-date-picker>
								</v-menu> -->
								</div>
								<div class="d-flex flex-column" v-if="estudio.paquete==false">
									<b v-if="estudio.estudioId!=2||estudio.covid">Hora:</b>
                  <v-select v-if="estudio.covid || estudio.estudioId!=2" placeholder="Horarios" label="hora" v-model="hora[i]" :options="horarios[i]" :disabled="loading" >
                    <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                            No se encontró el horario: <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                    </template>
                </v-select>
								</div>
								<div class="d-flex flex-column" v-else v-for="(sub, h) in estudio.paquete" :key="h">
									<b v-if="sub.estudioId!=2" class="estudio">{{sub.subEstudioVal}}</b >
                  <v-select v-if="sub.estudioId!=2" placeholder="Horarios" v-model="hora[i][h]" label="hora" :options="horarios[i][h]" >
                    <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                            No se encontró el horario: <em>{{ search }}</em>.
                        </template>
                        <em style="opacity: 0.5;" v-else>Empieza a buscar...</em>
                    </template>
                  </v-select>
								</div>
							</div>
						</div>
					</div>
				</div>
        <div class="row">
          <div class="col">
            <button @click="pagina=2" class="btn btn-danger btn-sm mt-3 mb-3">Atras</button>
            <button  @click="reagendarCita" class="btn btn-success btn-sm float-right mt-3 mb-3">Reagendar</button>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
library.add(faChevronRight)
import devolucionesService from '../../services/devoluciones'
import marketingService from '../../services/marketing'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/index.css'
import Vue from 'vue'
import Calendar from 'primevue/calendar'
Vue.use(VueToast)
export default {
  name: 'Devoluciones',
  components: {
    'font-awesome-icon': FontAwesomeIcon,
    'vSelect': vSelect,
    'Calendar': Calendar
  },
  data() {
    return {
        pagina:1,
        devolucion:{nombrePaciente:'', nombreSucursal:'', correo:'', estudios:[]},
        mostrar:true,
        folio:null,
        infoReagendar: {
          "estatus": 1,
          "tipoError": 1,
          "cita": 17953947,
          "sucursalId": 46,
          "sucursal": "coyoacán",
          "estudios": [
              {
                  "estudioId": 2,
                  "nombreEstudio": "Laboratorio",
                  "colorEstudio": "#359FDA",
                  "imagen": "https://salud-digna.com/email/CitasNuevo/laboratorio2.png",
                  "subEstudioId": 17087,
                  "subEstudioVal": "GLUCOSA",
                  "idCita": 688172771,
                  "idCitaSisPrev": 17953947,
                  "sucursalId": 46,
                  "fecha": "12/07/2020 00:00:00",
                  "hora": "6:00AM",
                  "paquete": false
              },
              {
                  "estudioId": 16,
                  "nombreEstudio": "Nutricion",
                  "colorEstudio": "#60A463",
                  "imagen": "https://salud-digna.com/email/CitasNuevo/nutri2.png",
                  "subEstudioId": 33674,
                  "subEstudioVal": "ASESORIA NUTRICIONAL",
                  "idCita": 688172771,
                  "idCitaSisPrev": 17953948,
                  "sucursalId": 46,
                  "fecha": "12/07/2020 00:00:00",
                  "hora": "2:40PM",
                  "paquete": false
              },
              {
                  "idPaquete": 6676,
                  "estudioId": 2,
                  "nombreEstudio": "PAQUETE PERFIL ELECTROLITOS 6 PARAMETROS",
                  "colorEstudio": "#FF9800",
                  "imagen": "https://salud-digna.com/email/CitasNuevo/laboratorio2.png",
                  "fecha": "12/08/2020 00:00:00",
                  "paquete": [
                      {
                          "estudioId": 2,
                          "nombreEstudio": "Laboratorio",
                          "imagen": "https://salud-digna.com/email/CitasNuevo/laboratorio2.png",
                          "subEstudioId": 17013,
                          "subEstudioVal": "calcio",
                          "idCita": 688172771,
                          "idCitaSisPrev": 17953949
                      },
                      {
                          "estudioId": 2,
                          "nombreEstudio": "Laboratorio",
                          "imagen": "https://salud-digna.com/email/CitasNuevo/laboratorio2.png",
                          "subEstudioId": 17023,
                          "subEstudioVal": "cloro cl",
                          "idCita": 688172771,
                          "idCitaSisPrev": 17953950
                      },
                      {
                          "estudioId": 2,
                          "nombreEstudio": "Laboratorio",
                          "imagen": "https://salud-digna.com/email/CitasNuevo/laboratorio2.png",
                          "subEstudioId": 17078,
                          "subEstudioVal": "fosforo",
                          "idCita": 688172771,
                          "idCitaSisPrev": 17953951
                      },
                      {
                          "estudioId": 2,
                          "nombreEstudio": "Laboratorio",
                          "imagen": "https://salud-digna.com/email/CitasNuevo/laboratorio2.png",
                          "subEstudioId": 17109,
                          "subEstudioVal": "magnesio",
                          "idCita": 688172771,
                          "idCitaSisPrev": 17953952
                      },
                      {
                          "estudioId": 2,
                          "nombreEstudio": "Laboratorio",
                          "imagen": "https://salud-digna.com/email/CitasNuevo/laboratorio2.png",
                          "subEstudioId": 17120,
                          "subEstudioVal": "potasio   k",
                          "idCita": 688172771,
                          "idCitaSisPrev": 17953953
                      },
                      {
                          "estudioId": 2,
                          "nombreEstudio": "Laboratorio",
                          "imagen": "https://salud-digna.com/email/CitasNuevo/laboratorio2.png",
                          "subEstudioId": 17136,
                          "subEstudioVal": "sodio   na",
                          "idCita": 688172771,
                          "idCitaSisPrev": 17953954
                      }
                  ]
              },
              {
                  "idPaquete": 56450,
                  "estudioId": 1,
                  "nombreEstudio": "PAQUETE MUJER -BASE LIQUIDA-",
                  "colorEstudio": "#FF9800",
                  "imagen": "https://salud-digna.com/email/CitasNuevo/densitometria2.png",
                  "fecha": "12/09/2020 00:00:00",
                  "paquete": [
                      {
                          "estudioId": 1,
                          "nombreEstudio": "Densitometria",
                          "imagen": "https://salud-digna.com/email/CitasNuevo/densitometria2.png",
                          "subEstudioId": 16949,
                          "subEstudioVal": "densitometria osea de columna y femur",
                          "idCita": 688172771,
                          "idCitaSisPrev": 17953955
                      },
                      {
                          "estudioId": 3,
                          "nombreEstudio": "Mastografia",
                          "imagen": "https://salud-digna.com/email/CitasNuevo/mastografia2.png",
                          "subEstudioId": 17158,
                          "subEstudioVal": "mastografia bilateral",
                          "idCita": 688172771,
                          "idCitaSisPrev": 17953956
                      },
                      {
                          "estudioId": 4,
                          "nombreEstudio": "Papanicolaou",
                          "imagen": "https://salud-digna.com/email/CitasNuevo/papanicolau2.png",
                          "subEstudioId": 33836,
                          "subEstudioVal": "papanicolaou base liquida",
                          "idCita": 688172771,
                          "idCitaSisPrev": 17953957
                      }
                  ]
              }
          ]
        },
        horarios:[],
        hora:[],
        dateplaceholder: new Date(),
        fechaLimite: null,
        fechaCovid:null,
        date: [],
        fecha:null,
        status:false,
        motivo:null,
        alerta:0,
        token:null,
        motivos: [
          "No pude asistir a mi cita",
          "Me hice el estudio en otra parte",
          "No era el estudio correcto",
          "La clínica me queda muy lejos",
          "El horario no era adecuado para mí",
          "Otro",
        ],
        es: {
          firstDayOfWeek: 1,
          dayNames: [ "Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado" ],
          dayNamesShort: [ "Dom","Lun","Mar","Mié","Jue","Vie","Sáb" ],
          dayNamesMin: [ "D","L","M","X","J","V","S" ],
          monthNames: [ "Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ],
          monthNamesShort: [ "Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic" ],
          today: 'Hoy',
          clear: 'Borrar',
          weekHeader: 'Sm'
        },
        minDate:null,
        maxDate:null
      }
    },

    created(){
        this.$bus.$off('limpiarRSV')
        this.$bus.$on('limpiarRSV', () => {
            //this.limpiar()
        })
        // this.getCita()
        var moment = require('moment')
        moment.locale('es')
        let today = new Date();
        let month = today.getMonth();
        let year = today.getFullYear();
        let prevMonth = (month === 0) ? 11 : month -1;
        let prevYear = (prevMonth === 11) ? year - 1 : year;
        let nextMonth = (month === 11) ? 0 : month + 1;
        let nextYear = (nextMonth === 0) ? year + 1 : year;
        this.minDate = new Date()
        this.minDate.setMonth(prevMonth)
        this.minDate.setFullYear(prevYear)
        this.maxDate = new Date()
        this.maxDate.setMonth(nextMonth)
        this.maxDate.setFullYear(nextYear)
        this.fechaCovid=moment(this.dateplaceholder).add(3, 'days')
    },
    methods:{
      pedirToken(){
        this.status=true
        devolucionesService.getCode(this.folio, this.fecha).then(resp =>{
          this.token = resp.code
          if (resp.estatus == 0 || resp.Estatus == 0 || resp.Message=="The request is invalid.") {
            this.alerta = 1
            this.status=false
          } else {
            this.getCita();
            this.pagina=2
            this.status=false
          }
        })
      },
      getCita(){
        devolucionesService.getCita(this.token).then((resp)=>{
          this.devolucion=resp
          this.fechaLimite = this.devolucion.fechaLimite
        })
      },
      cancelarCita(){
        let cancelar = {
          Id: [this.folio],
          Filtro: 2
        }
        marketingService.cancelarCita(cancelar).then((resp)=>{
          
          if(resp){
            this.pagina=1
            this.alerta=3
            Vue.$toast.open({
                message: 'Cancelación realizada con exito',
                position: 'top-right',
                type: 'default',
                duration: 5000
            })
            this.$gtag.event('devoluciones/cancelaciones',{
              'event_category': 'cancelacion',
              'event_label': 'clinica: '+ this.devolucion.nombreSucursal,
              'value': this.devolucion.total
            })
          }
        })
      },
      pedirReembolso(){
        let datos={
          code: this.token,
          motivo: this.motivo,
          plataforma: 2
        }
        devolucionesService.devolucion(datos).then((resp)=>{
          this.pagina=1
          this.alerta=2
          if(resp.estatus){
            Vue.$toast.open({
                message: 'Devolución realizada con exito',
                position: 'top-right',
                type: 'default',
                duration: 5000
            })
            this.$gtag.event('devoluciones/cancelaciones',{
              'event_category': 'devolucion',
              'event_label': 'clinica: '+ this.devolucion.nombreSucursal,
              'value': this.devolucion.total
            })

          }
        })
      },
      reagendarInfo(){
        devolucionesService.reagendarInfo(this.token).then((resp) => {
          this.infoReagendar=resp.data
          this.iterar()
          for(let h; h < this.infoReagendar.estudios.length; h++){
            this.date[h]=this.dateplaceholder
          }
          this.pagina=3
        });
      },
      iterar(){
        this.infoReagendar.estudios.forEach((estudio) => {
          estudio.paquete?this.hora.push({hora:[]}):this.hora.push({hora:''})
          estudio.paquete?this.horarios.push({hora:[{hora:'Sin horarios disponibles', id:0}]}):this.horarios.push({hora:'Sin horarios disponibles', id:0})
        });
        this.hora.push({hora:''})
      },
      async consultarHorarios(arreglo, indice){
        this.horarios[indice]=[];
        if(!arreglo.paquete){
          
          let covid=(arreglo.covid)?true:false
          let datos={
            estudioId: arreglo.estudioId,
            sucursalId: arreglo.sucursalId,
            fecha: this.date[indice],
            subEstudioId: arreglo.subEstudioId,
            subEstudioVal: arreglo.subEstudioVal,
            covid: covid
          }
          await devolucionesService.reagendarHorarios(datos).then((resp) => {
            if(!resp.data.estatus){
              this.horarios[indice]={hora:'Sin horarios disponibles', id:0};
            }else{
              this.horarios[indice]=resp.data.horarios;
            }
            this.$forceUpdate();
          }); 
        }else{
          var tempHora=[]
          for (let x = 0; x < arreglo.paquete.length; x++){
            let covid=(arreglo.paquete[x].subEstudioVal=='SARS-COV-2 (COVID-19)')?true:false
            let datos={
              estudioId: arreglo.paquete[x].estudioId,
              sucursalId: this.infoReagendar.sucursalId,
              fecha: this.date[indice],
              subEstudioId: arreglo.paquete[x].subEstudioId,
              covid: covid
            }
            await devolucionesService.reagendarHorarios(datos).then((resp) => {
              
              if(!resp.data.estatus){
                tempHora[x]={hora:'Sin horarios disponibles', id:0};
              }else{
                //let obj = {[x]:resp.data.horarios}
                if(arreglo.paquete[x].estudioId==2){
                  this.hora[indice][x]=resp.data.horarios[0]
                } else {
                  tempHora[x]=resp.data.horarios;
                }
              }
              
                // const hour=[]
                // resp.data.horarios.forEach((horas, y) => {
                //   let intervalito = Object.assign(horas, {identificador: y});
                //   hour.push(intervalito);
                // })
            });

          }
          this.horarios[indice]=tempHora;
          this.$forceUpdate();
        }
        this.valid=true
        
        
      },
      reagendarCita(){
        let estudios=[];
        this.loading = true;
        let covid=false;
        for(let y = 0; y < this.infoReagendar.estudios.length; y++){
          covid=(this.infoReagendar.estudios[y].covid)?true:false;
            let estudio = []
            if(covid){
              estudio={
                idCitaSisPrev:this.infoReagendar.estudios[y].idCitaSisPrev,
                fecha:this.date[y],
                horario: this.hora[y].hora,
                idHorarioMKT: this.hora[y].idHorarioMKT,
                idHorarioSis: this.hora[y].idHorarioSis,
                idCita:this.infoReagendar.estudios[y].idCita,
                
              };
              estudios.push(estudio)
            }else{
              if(this.infoReagendar.estudios[y].paquete){
                for(let z = 0; z < this.infoReagendar.estudios[y].paquete.length; z++){
                  estudio={
                    idCitaSisPrev:this.infoReagendar.estudios[y].paquete[z].idCitaSisPrev,
                    fecha:this.date[y],
                    horario: this.infoReagendar.estudios[y].paquete[z].estudioId!=2?this.hora[y][z].hora:'',
                    idHorario: this.infoReagendar.estudios[y].paquete[z].estudioId!=2?this.hora[y][z].id:null
                  };
                  estudios.push(estudio)
                }
              }else{
                  if(this.infoReagendar.estudios[y].estudioId==2){
                    estudio={
                      idCitaSisPrev:this.infoReagendar.estudios[y].idCitaSisPrev,
                      fecha:this.date[y],
                      horario: this.horarios[0][0].hora,
                      idHorario: this.horarios[0][0].id
                    };
                    estudios.push(estudio)
                  }else{
                    estudio={
                      idCitaSisPrev:this.infoReagendar.estudios[y].idCitaSisPrev,
                      fecha:this.date[y],
                      horario: this.hora[y].hora,
                      idHorario: this.hora[y].id
                    };
                    estudios.push(estudio)
                  }
                }
            }
            
          
          
        }
        let datos = null

        if (this.otrosstatus == 1) {
          datos = {
            code: this.token,
            motivo: this.otrosvalue,
            covid: covid,
            estudios: estudios,
            plataforma: 2
          }
        } else {
          datos = {
            code: this.token,
            motivo: this.motivo,
            covid: covid,
            estudios: estudios,
            plataforma: 2
          }
        }
        
        devolucionesService.reagendarCita(datos).then((resp) => {
          if(resp.data.estatus){
            this.citaReagendada=resp.data;
            this.loading = false;
            this.pagina=1
            Vue.$toast.open({
                message: 'Reagendado realizado con exito',
                position: 'top-right',
                type: 'default',
                duration: 5000
            })
            this.$gtag.event('devoluciones/cancelaciones',{
              'event_category': 'reagendado',
              'event_label': 'clinica: '+ this.devolucion.nombreSucursal,
              'value': this.devolucion.total
            })
          }
        });
      },
      copiar(){
        navigator.clipboard.writeText('https://salud-digna.org/cambiosenmicita/?token='+this.token).then(() => {
                
            })
            .catch(e => {
                console.error(e)
            });    
      }
    },
}
</script>
<style>
.p-calendar .p-datepicker{
  top: 208px!important;
  left: unset!important;
  right: 21px!important;
  position: fixed!important;
  min-width: 30%!important;
  display: unset!important;
}
</style>