<template>
<div class="row componenteSuperior">
    <div class="col-md-4 col-lg-3 col-xx-2 elegirChat d-none d-lg-block d-xl-block">
        <buscar />
        <!--filtros /-->
        <entrada-chats />
    </div>
    <div class="col-md-12 col-lg-9 col-xx-10 areaChat">
        <div id="wrapper" class=" " v-bind:class="{toggled: isActive}">

            <info-paciente />
            <herramientas-mensajes v-on:emitToggled="cerrarBarra" v-on:Toggled="abrir" />
            <mensajes/>

        </div>

    </div>
    
</div>
</template>

<script>
import Buscar from './Buscar.vue'
//import Filtros from './Filtros.vue'
import EntradaChats from './EntradaChats.vue'
import Mensajes from './Mensajes.vue'
import HerramientasMensajes from './HerramientasMensajes.vue'
import InfoPaciente from './InfoPaciente.vue'
export default {
    name: 'ComponenteSuperior',
    components: {
        Buscar,
        //Filtros,
        EntradaChats,
        Mensajes,
        HerramientasMensajes,
        InfoPaciente

    },
    data() {
        return {
            isActive: false
        }
    },
    props: ['toggledSidebar',
        'filtroOrigen'
    ],
    created() {
        this.$bus.$off('bajarScroll')
        this.$bus.$on('bajarScroll', (bandera) => {
            var chatBox;
            chatBox = document.querySelector('.areaChat')
            const scrollTopMax = chatBox.scrollHeight - chatBox.offsetHeight
            if(chatBox.scrollTop == scrollTopMax || bandera){
                // cuando exista un nuevo mensaje
                setTimeout(()=>chatBox.scrollTop = chatBox.scrollHeight);
            }
        })

        this.$bus.$off('subirScrollChat')
        this.$bus.$on('subirScrollChat', () => {
            var Scroll
            Scroll = document.querySelector('.elegirChat')
            // cuando exista una nueva conversacion
            Scroll.scrollTop = 0;
        })
        this.$bus.$off('cerrarBarra')
        this.$bus.$on('cerrarBarra', () => {
            this.isActive = false
        })
    },
    methods: {
        abrir: function () {
            this.isActive = true;
        },
        cerrarBarra(){
            this.isActive = false;
        }
    }

}
</script>
