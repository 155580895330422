<template>

<ul class="nav nav-tabs d-flex flex-row p-0 sticky">
    <li class="nav-item">
        <div class="nav-link todo" :class="{ active: a0 }" @click="clickTodo"><font-awesome-icon icon="inbox" class="mr-1"/> Inbox <span class="badge badge-secondary notificacion" :class="{'badge-danger': inbox>19, 'badge-success': inbox < 5, 'badge-warning': inbox>9 && inbox<20}">{{inbox}}</span></div>
    </li>
    <li class="nav-item">
        <div class="nav-link pendiente" :class="{ active: a1 }" @click="clickPendiente"><font-awesome-icon icon="hourglass-half" class="mr-2"/>Pendientes<span class="badge badge-secondary notificacion">{{espera}}</span></div>
    </li>
    <!--li class="nav-item">
        <div class="nav-link iconoFiltro letraChica aChat" :class="{ active: a1 }" @click="clickChat"><i class="far fa-comment"></i><span class="badge badge-secondary notificacion">0</span></div>
    </li>
    <li class="nav-item">
        <div class="nav-link iconoFiltro letraChica aWhatsapp" :class="{ active: a2 }" @click="clickWhats"><i class="fab fa-whatsapp"></i><span class="badge badge-secondary notificacion">0</span></div>
    </li>
    <li class="nav-item">
        <div class="nav-link iconoFiltro letraChica aMessenger" :class="{ active: a3 }" @click="clickMsn"><i class="fab fa-facebook-messenger"></i><span class="badge badge-secondary notificacion">0</span></div>
    </li>
    <li class="nav-item">
        <div class="nav-link iconoFiltro letraChica aInstagram" :class="{ active: a4 }" @click="clickInsta"><i class="fab fa-instagram"></i></div>
    </li>
    <li class="nav-item">
        <div class="nav-link letraChica btnHistorial" :class="{ active: a5 }" @click="clickHistorial"><i class="fas fa-history"></i></div>
    </li-->
</ul>
</template>
<style >
.sticky{
    position: sticky;
    z-index: 0;
    top: 0px;
}
</style>
<script>
import {library} from '@fortawesome/fontawesome-svg-core'
import { faInbox, faHourglassHalf } from '@fortawesome/free-solid-svg-icons'
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
library.add(faInbox, faHourglassHalf)
export default {
    name: 'Filtros',
    props: ['filtroOrigen'],
    components:{
        FontAwesomeIcon
    },
    data(){
        return{
            a0:true,
            a1:false,
            a2:false,
            a3:false,
            a4:false,
            chats: [],
            espera: 0,
            inbox: 0,
        }
    },
    created(){
        this.$bus.$off('espera1')
        this.$bus.$on('espera1', e=>{
            this.espera=e
        })
        this.$bus.$off('inbox1')
        this.$bus.$on('inbox1', i=>{
            this.inbox=i
        })
    },
    methods:{
        todoF() {
            this.a0=false;
            this.a1=false;
            this.a2=false;
            this.a3=false;
            this.a4=false;
            this.a5=false;
            
        },
        clickTodo() {
            this.todoF();
            this.a0=true;
            this.$bus.$emit('inbox', false); 
        },
        clickPendiente() {
            this.todoF();
            this.a1=true;
            this.$bus.$emit('espera', true); 
        },
        clickMsn() {
            this.todoF();
            this.a3=true;
        },
        clickInsta() {
            this.todoF();
            this.a4=true;
        },
        clickHistorial() {
            this.todoF();
            this.a5=true;
        }
    }
}
</script>
