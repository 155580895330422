import api from './api'
let service = {}

service.historialResultados = function(telefono, pagina) {
    return api.get(`/ConsultaResultados/ConsultaResultados/ObtenerResultadosNumeroPaciente?Telefono=${telefono}&Pagina=${pagina}`).then(res => res.data)
}
service.historialCitas = function(telefono, pagina) {
    return api.get(`SDCitas/HistorialPaciente/ObtenerCitasNumeroPaciente?Telefono=${telefono}&Pagina=${pagina}`).then(res => res.data)
}

export default service