<template>
    <div class="scroll">
        <h5 class="text-center">Ubica tu clínica</h5>

            <div class="input-group mb-3 sticky bg">
                <input v-model="buscar" @keypress.enter="buscarClinica(buscar)" type="search" class="form-control" placeholder="Buscar sucursal" :disabled="!status">
                <div class="input-group-append">
                    <button @click="buscarClinica(buscar)" class="btn btn-outline-success" type="button" :disabled="!status"><font-awesome-icon v-if="status" icon="search" /> <span v-else class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
                </div>
            </div>
            <div v-if="sucursales==false" class="alert alert-danger alert-dismissible fade show m-0 mt-1 p-2 mb-1" role="alert">
                    No se encontraron sucursales
                    <button @click="sucursales=true" type="button" class="close p-1">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            <li v-for="sucursal in sucursales" :key="sucursal.id" class="respuestaRapida ml-1 row">
            
                <button @click="responder(sucursal.Id, sucursal.Nombre)" class="btnEnviar float-left col-1">
                    <font-awesome-icon icon="share" flip="horizontal" />
                </button>
                <p class="col-11 mb-0">
                    <b>Sucursal:</b> {{sucursal.Nombre}}<br>
                    <b>Dirección:</b> {{sucursal.Direccion}}<br>
                    <b>Horario:</b> {{sucursal.HorarioAtencion}}<br>
                    <b>Teléfono:</b> {{sucursal.Telefonos}}<br>


                </p>
                
                
            
        </li>
    </div>
</template>
<script>
import {
    FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'
import asesorService from '../../services/asesor'
export default {
    name:'Ubica',
    components: {
        'font-awesome-icon': FontAwesomeIcon
    },
    data() {
        return {
            buscar: '',
            status: true,
            sucursales: null
        }
    },
    methods:{
        buscarClinica(c){
            this.status=false
            asesorService.ubicarClinica(c).then(clinica => {
                clinica = clinica.map(x=>{
                   x.HorarioAtencion=x.HorarioAtencion.replace("<br>", "\n").replace("<br>", "\n")
                    return x
                })


                this.sucursales=clinica
                this.status = true
               
            }).catch(err =>{
                this.status = true
                return err
            })
            
        },
        responder(id, clinica){
            asesorService.enviarUbicacionClinica(id).then((res) => {
                this.sucursales=res
                this.$gtag.event('ubicacionesEnviadas',{
                    'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                    'event_label': clinica,
                    'value': 1
                })
            }).catch(err =>{
                return err
            })
        }
    }
}
</script>