<template>
<div class="scroll">
    <h5 class="text-center">Flujos</h5>
    <div class="card mb-3">
        <div class="card-header"><h5>Menú</h5></div>
        <div class="card-body">
            <h4 v-if="confirmacion0" class="card-title">¿Estás seguro?</h4>
            <p class=""><button v-if="!confirmacion0" @click="aMenu()" class="btn btn-success">Enviar a Menú</button>
            <button v-if="confirmacion0" @click="confirmacion0=false" class="btn btn-danger">Cancelar</button>
            <button class="btn btn-success" v-if="confirmacion0" @click="flujo">Aceptar</button></p>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header"><h5>Consultar resultados 🔔</h5></div>
        <div class="card-body">
            <h4 v-if="confirmacion1" class="card-title">¿Estás seguro?</h4>
            <p class=""><button v-if="!confirmacion1" @click="aResultado" class="btn btn-success">Enviar a flujo</button>
            <button v-if="confirmacion1" @click="confirmacion1=false" class="btn btn-danger">Cancelar</button>
            <button class="btn btn-success" v-if="confirmacion1" @click="flujo">Aceptar</button></p>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header"><h5>Agendar una cita 🗓️</h5></div>
        <div class="card-body">
            <h4 v-if="confirmacion3" class="card-title">¿Estás seguro?</h4>
            <p class=""><button v-if="!confirmacion3" @click="aCitas" class="btn btn-success">Enviar a flujo</button>
            <button v-if="confirmacion3" @click="confirmacion3=false" class="btn btn-danger">Cancelar</button>
            <button class="btn btn-success" v-if="confirmacion3" @click="flujo">Aceptar</button></p>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header"><h5>Información de clínica 🗺️</h5></div>
        <div class="card-body">
            <h4 v-if="confirmacion4" class="card-title">¿Estás seguro?</h4>
            <p class=""><button v-if="!confirmacion4" @click="aInfo" class="btn btn-success">Enviar a flujo</button>
            <button v-if="confirmacion4" @click="confirmacion4=false" class="btn btn-danger">Cancelar</button>
            <button class="btn btn-success" v-if="confirmacion4" @click="flujo">Aceptar</button></p>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header"><h5>Menú Covid🦠</h5></div>
        <div class="card-body">
            <h4 v-if="confirmacion5" class="card-title">¿Estás seguro?</h4>
            <p class=""><button v-if="!confirmacion5" @click="aCovid" class="btn btn-success">Enviar a flujo</button>
            <button v-if="confirmacion5" @click="confirmacion5=false" class="btn btn-danger">Cancelar</button>
            <button class="btn btn-success" v-if="confirmacion5" @click="flujo">Aceptar</button></p>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header"><h5>Queja 😠</h5></div>
        <div class="card-body">
            <h4 v-if="confirmacion6" class="card-title">¿Estás seguro?</h4>
            <p class=""><button v-if="!confirmacion6" @click="aQueja" class="btn btn-success">Enviar a flujo</button>
            <button v-if="confirmacion6" @click="confirmacion6=false" class="btn btn-danger">Cancelar</button>
            <button class="btn btn-success" v-if="confirmacion6" @click="flujo">Aceptar</button></p>
        </div>
    </div>
    <div class="card mb-3">
        <div class="card-header"><h5>Aliados por la salud 💜</h5></div>
        <div class="card-body">
            <h4 v-if="confirmacion7" class="card-title">¿Estás seguro?</h4>
            <p class=""><button v-if="!confirmacion7" @click="aAliados" class="btn btn-success">Enviar a flujo</button>
            <button v-if="confirmacion7" @click="confirmacion7=false" class="btn btn-danger">Cancelar</button>
            <button class="btn btn-success" v-if="confirmacion7" @click="flujo">Aceptar</button></p>
        </div>
    </div>
</div>
</template>

<script>
import asesorService from '../../services/asesor'
export default {
    name: 'Flujos',
    data() {
        return {
            clic: 0,
            confirmacion0:false,
            confirmacion1:false,
            confirmacion3:false,
            confirmacion4:false,
            confirmacion5:false,
            confirmacion6:false,
            confirmacion7:false
        }
    },
    methods: {
        flujo(){
            asesorService.flujos(this.clic).then(() => { 
                sessionStorage.removeItem('senderid')
                sessionStorage.removeItem('sender_actual')
                this.$bus.$emit('terminar-conversacion')
                this.reset()
             })
        },
        reset(){
            this.confirmacion0=false
            this.confirmacion1=false
            this.confirmacion3=false
            this.confirmacion4=false
            this.confirmacion5=false
            this.confirmacion6=false
            this.confirmacion7=false
        },
        aMenu(){
            this.clic=0
            this.reset()
            this.confirmacion0=true
        },
        aResultado(){
            this.clic=1
            this.reset()
            this.confirmacion1=true
        },
        aCitas(){
            this.clic=3
            this.reset()
            this.confirmacion3=true
        },
        aInfo(){
            this.clic=4
            this.reset()
            this.confirmacion4=true
        },
        aCovid(){
            this.clic=5
            this.reset()
            this.confirmacion5=true
        },
        aQueja(){
            this.clic=6
            this.reset()
            this.confirmacion6=true
        },
        aAliados(){
            this.clic=7
            this.reset()
            this.confirmacion7=true
        }
    }
}
</script>
