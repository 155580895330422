<template>
    <div class="scroll">
        <p class="p-rsv">Ingresa el RSV/OPC que se quiere levantar Queja o folio de Queja que se quiere consultar:</p>
                    <div v-show="alerta==1" class="alert alert-info alert-dismissible fade show m-2 mt-3 p-2 py-3" :class="{'alert-danger': alerta==1}" role="alert">
                        {{'Folio invalido'}}
                        <button @click="cerrar" type="button" class="close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div v-show="alerta==2" class="alert alert-info alert-dismissible fade show m-2 mt-3 p-2 py-3" role="alert">
                        {{'Folio: '+ this.folio}}
                        <button @click="copiar()" class="btn rounded-circle mr-3 float-left" style="margin-left: 10px;margin-top: -10px;">
                                        <font-awesome-icon icon="copy" :style="{ color: 'gray' }"/>
                                </button>
                        <button @click="cerrar" type="button" class="close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="row">
                        <div v-show="alerta==3" class="alert alert-info alert-dismissible fade show m-2 mt-3 p-2 py-3 col-lg-11" :class="{'alert-danger': alerta==3}" role="alert">
                        {{this.mensajeError}}
                        <button @click="cerrar" type="button" class="close col-sm-1">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                    </div>
                    
        <div class="input-group mb-3">
          <input
            v-model="reservacion"
            @keypress.enter="consultarRSV(reservacion)"
            type="search"
            class="form-control"
            placeholder="RSV/OPC"
            :disabled="!status"
          />
          <div class="input-group-append">
            <button
              @click="consultarRSV(reservacion)"
              class="btn btn-outline-success"
              type="button"
              :disabled="!status"
            >
              <font-awesome-icon v-if="status" icon="search" />
              <span
                v-else
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
        <div v-show="alerta==4" class="alert alert-dismissible fade show m-2 mt-3 p-2 py-3" :class="{'alert-danger': estatusQ==0,'alert-secondary': estatusQ==1,'alert-primary': estatusQ==2,'alert-success': estatusQ==3,'alert-dark': estatusQ==4}" role="alert">
            <span style="font-weight: bold">Estatus: </span><br><br> {{ this.mensajeError }}
                        <button @click="cerrar" type="button" class="close">
                            <span aria-hidden="true">&times;</span>
                        </button>

                    </div>
        <div v-show="tipo==1">
                    <span class="input-text">Nombre</span>
                    <input v-model="info.Nombre" type="text" class="form-control" :disabled="info">

                    <span class="input-text">Apellido Paterno</span>
                    <input v-model="info.Paterno" type="text" class="form-control" :disabled="info">

                    <span class="input-text">Apellido Paterno</span>
                    <input v-model="info.Materno" type="text" class="form-control" :disabled="info">

                    <span class="input-text">Sexo</span>
                    <input v-if="info.Sexo===2" placeholder="Masculino" type="text" class="form-control" :disabled="info">
                    <input v-else-if="info.Sexo===1" placeholder="Femenino" type="text" class="form-control" :disabled="info">

                    <span class="input-text">Sucursal</span>
                    <input v-model="sucursal.Nombre" type="text" class="form-control" :disabled="info">

                    <span class="input-text">Estudio</span>
                    <v-select class="pypCombo" placeholder="Seleccione el estudio" :options="estudios" label="Descripcion" v-model="SelectedEstudio" @input="getMotivos">
                    </v-select>

                    <span class="input-text">Motivo</span>
                    <v-select class="pypCombo" placeholder="Seleccione un motivo" :options="filtroMotivos" label="descripcion" v-model="SelectedMotivo" :disabled="!SelectedEstudio">
                    </v-select>

                    <span class="input-text">Comentario</span>
                    <textarea v-model="info.comentario" type="text" class="form-control" maxlength="500"></textarea>

                    <span class="input-text">Telefono de Contacto</span>
                    <input v-model="telefonoContacto" type="text" class="form-control">

                    <span class="input-text">Telefono de Alternativo</span>
                    <input v-model="telefonoAlternativo" type="text" class="form-control">

                    <div class="form-group" style="margin-top:15px ;">
                        <button v-if="SelectedEstudio==''||SelectedMotivo==''||info.comentario==''||telefonoContacto==''" type="button" class="btn btn-primary" disabled> Generar</button>
                        <button v-else type="button" class="btn btn-primary" @click="crear()"> Generar</button>
                        <button type="button" class="btn btn-secondary" @click="borrar()" style="margin-left: 10px;">Limpiar <font-awesome-icon icon="trash"/></button>
                    </div>
                    <!-- <div class="row" v-show="folios">
                                <button @click="copiar()" class="btn rounded-circle mr-3 float-left" style="margin-left: 10px;">
                                        <font-awesome-icon icon="copy" :style="{ color: 'gray' }"/>
                                </button>
                                <b class="float-right mr-5 mt-2" style="margin-top: 10px;">Folio</b>
                        <input v-model="folio" type="text" class="form-control" disabled style="width: auto;margin-left: 10px;margin-top: 5px;">
                    </div> -->
                            
        </div>
        <div v-show="tipo==2">
                    <span class="input-text">Nombre</span>
                    <input v-model="info.Nombre" type="text" class="form-control" :disabled="info">

                    <span class="input-text">Apellido Paterno</span>
                    <input v-model="info.Paterno" type="text" class="form-control" :disabled="info">

                    <span class="input-text">Apellido Paterno</span>
                    <input v-model="info.Materno" type="text" class="form-control" :disabled="info">

                    <span class="input-text">Sexo</span>
                    <input v-if="info.Sexo===2" placeholder="Masculino" type="text" class="form-control" :disabled="info">
                    <input v-else-if="info.Sexo===1" placeholder="Femenino" type="text" class="form-control" :disabled="info">

                    <span class="input-text">Sucursal</span>
                    <input v-model="sucursal.Nombre" type="text" class="form-control" :disabled="info">

                    <span class="input-text">Estudio</span>
                    <v-select class="pypCombo" placeholder="Seleccione el estudio" :options="estudios" label="Descripcion" v-model="SelectedEstudio" disabled >
                    </v-select>

                    <span class="input-text">Motivo</span>
                    <v-select class="pypCombo" placeholder="Seleccione un motivo" :options="filtroMotivos" label="descripcion" v-model="SelectedMotivo" :disabled="!SelectedEstudio">
                    </v-select>

                    <span class="input-text">Comentario</span>
                    <textarea v-model="info.comentario" type="text" class="form-control" maxlength="500"></textarea>

                    <span class="input-text">Telefono de Contacto</span>
                    <input v-model="telefonoContacto" type="text" class="form-control">

                    <span class="input-text">Telefono de Alternativo</span>
                    <input v-model="telefonoAlternativo" type="text" class="form-control">

                    <div class="form-group" style="margin-top:15px ;">
                        <button v-if="SelectedEstudio==''||SelectedMotivo==''||info.comentario==''||telefonoContacto==''" type="button" class="btn btn-primary" disabled> Generar</button>
                        <button v-else type="button" class="btn btn-primary" @click="crear()"> Generar</button>
                        <button type="button" class="btn btn-secondary" @click="borrar()" style="margin-left: 10px;">Limpiar <font-awesome-icon icon="trash"/></button>
                    </div>
                    <!-- <div class="row" v-show="folios">
                                <button @click="copiar()" class="btn rounded-circle mr-3 float-left" style="margin-left: 10px;">
                                        <font-awesome-icon icon="copy" :style="{ color: 'gray' }"/>
                                </button>
                                <b class="float-right mr-5 mt-2" style="margin-top: 10px;">Folio</b>
                        <input v-model="folio" type="text" class="form-control" disabled style="width: auto;margin-left: 10px;margin-top: 5px;">
                    </div> -->

        </div>
    </div>
</template>
<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faShare,
  faEye,
  faDownload,
  faGlasses,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faShare, faEye, faDownload, faGlasses);
import VueToast from "vue-toast-notification";
import Vue from "vue";
import asesorService from '../../services/asesor'
Vue.use(VueToast);
export default{
    data(){
        return{
            sender:sessionStorage.getItem('sender_actual').slice(3),
            sucursales: require('../../assets/json/sucursales.json'),
            estudios:require('../../assets/json/estudios_lista.json'),
            status:true,
            reservacion: "",
            tipo:"",
            resev:"",
            info:"",
            sucursal:"",
            SelectedEstudio:"",
            SelectedMotivo:"",
            filtroMotivos:"",
            telefonoContacto:"",
            telefonoAlternativo:null,
            prefijo:"",
            alerta:0,
            estatusQ:0,
            folio:"",
            mensajeError:null,
            folios:false,
            motivos:[{"id":316,"descripcion":"Atención inadecuada"},
                    {"id":216,"descripcion":"Atención inadecuada"},
                    {"id":313,"descripcion":"Atención inadecuada"},
                    {"id":312,"descripcion":"Atención inadecuada"},
                    {"id":14,"descripcion":"Atención inadecuada"},
                    {"id":220,"descripcion":"Atención inadecuada"},
                    {"id":171,"descripcion":"Tiempo de espera"},
                    {"id":166,"descripcion":"Tiempo de entrega"},
                    {"id":269,"descripcion":"Resultado erróneo"},
                    {"id":77,"descripcion":"Interpretación errónea"},
                    {"id":63,"descripcion":"Interpretación errónea"}]
        }
    },
    components:{
        "font-awesome-icon": FontAwesomeIcon,
        vSelect
    },
    mounted(){
        //this.obtenerToken()
    },
    methods:{
        async consultarRSV(){
            this.resev=this.reservacion
            this.status=false
            this.prefijo=this.reservacion.substring(0, 3).toLowerCase();
            let quitarprefijo = this.resev.replace(/\D/g, '');
                if (this.prefijo === 'rsv') {
                    this.resev=quitarprefijo
                    this.tipo = '1';
                    Vue.$toast.open({
                    message: "Se está buscando el resultado...",
                    position: "top-right",
                    type: "default",
                    duration: 5000,
                    });
                } else if (this.prefijo === 'opc') {
                    this.SelectedEstudio={"id_area": 7,"id_motivo":[14,171,166,63],"Descripcion": "Lentes"}
                    this.resev=quitarprefijo
                    this.tipo = '2';
                    this.getMotivos()
                    Vue.$toast.open({
                    message: "Se está buscando el resultado...",
                    position: "top-right",
                    type: "default",
                    duration: 5000,
                    });
                } else {
                    this.alerta=4
                    this.getEstQueja()
                    this.resev=''
                    this.tipo = '';
                }
                if(this.tipo==1||this.tipo==2){
                    await asesorService.getTokenSdigna().then(resp=>{
                    
                        let headers = { 'Authorization': resp.mensaje };
                        asesorService.getInfoPaciente(this.resev,this.tipo,headers).then(resp=>{
                            this.info=resp[0]
                            let idToCheck = parseInt(this.info.IdSucursal);
                            this.sucursal = this.sucursales.find(item => item.Id === idToCheck);
                        })
                        this.telefonoContacto=this.sender
                        
                    })
                    this.status=true
                }
        },
        getMotivos(){
            let resultadoFiltrado = this.motivos.filter(item => this.SelectedEstudio.id_motivo.includes(item.id));
            this.filtroMotivos=resultadoFiltrado
        },
        borrar(){
            this.tipo=0
            this.resev=""
            this.info=""
            this.sucursal=""
            this.SelectedEstudio=""
            this.SelectedMotivo=""
            this.telefonoContacto=""
            this.telefonoAlternativo=null
            this.prefijo=""
            this.folio=""
            this.alerta=0
            this.folios=false
            this.reservacion=""
            this.mensajeError=null
        },
        async crear(){
            let telefonocon=this.telefonoContacto
            let data={
                    "rsv_opc":this.resev ,
                    "nombre": this.info.Nombre+" "+this.info.Paterno+" "+this.info.Materno,
                    "idClinica": this.info.IdSucursal,
                    "nombreClinica": this.sucursal.Nombre,
                    "estudio": this.SelectedEstudio.Descripcion,
                    "idArea":this.SelectedEstudio.id_area,
                    "motivo":this.SelectedMotivo.descripcion,
                    "idMotivo": this.SelectedMotivo.id,
                    "comentario": this.info.comentario,
                    "telefonoAlternativo": this.telefonoAlternativo,
                    "telefonoContacto": telefonocon,
                    "tipo": this.prefijo
            }
            await asesorService.getTokenSdignaQ().then(resp=>{
                let headers = { 'Authorization': resp.token };
                    asesorService.crearQueja(data,headers).then(resp=>{
                        this.folio=resp.folio.folio
                        this.alerta=2
                        Vue.$toast.open({
                        message: "Se creo con Exito",
                        position: "top-right",
                        type: "default",
                        duration: 5000,
                    })
                    this.folios=true
                    //this.mostrarAlerta(this.folio)
                    }).catch(error => {
                        console.error("Error al llamar a la API:", error.data.msj);
                        this.mensajeError=error.data.msj
                        this.alerta = 3; // Establecer el valor de this.alerta en 3 para mostrar la alerta
                    });
                })
        },
        copiar(){
            let foliocop=this.folio
            let copi=`Tu reporte se ha enviado al departamento de Experiencia del Paciente. Tu folio es *`+foliocop+`*.
Es importante que estés pendiente a la llamada para brindarte el seguimiento correspondiente con la lada (667).

Para nosotros, tus comentarios son muy importantes. ¡Estamos aquí para servirte!
No es necesario que respondas a este mensaje`
            navigator.clipboard.writeText(copi)
        },
       async getEstQueja(){
            await asesorService.getTokenSd().then(resp=>{
                let headers = { 'Authorization': resp.mensaje };
                    asesorService.getEstQueja(this.reservacion,headers).then(resp=>{
                        if (resp && resp.estatus) {
                            this.mensajeError = resp.estatus;
                            this.estatusQ = resp.idEstatus;
                            this.status = true;
                        } else {
                            this.alerta=1
                            this.status=true
                        }
                    })
                })
        },
        cerrar(){
            this.alerta=0
            this.reservacion=""
        }
        
    }
}
</script>