<template>
<div class="col-md-12 col-lg-9 col-xx-10 entradaTexto px-2">
    <div class="container-fluid Txt contenedorTxt">

        <emoji-picker @emoji="insert" :search="search">
            <div class="emoji-invoker" slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }" @click.stop="clickEvent">
                <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z" />
                </svg>
            </div>
            <div slot="emoji-picker" slot-scope="{ emojis, insert }">
                <div class="emoji-picker">
                    <div class="emoji-picker__search">
                        <input type="text" v-model="search" v-focus placeholder="Buscar emoji" class="txtBuscar">
                    </div>
                    <div>
                        <div v-for="(emojiGroup, category, i) in emojis" :key="category">
                            <h5 v-if="i == 0">Comunes</h5><h5 v-else-if="i == 1">Caras</h5><h5 v-else-if="i == 2">Naturaleza</h5><h5 v-else-if="i == 3">Objetos</h5><h5 v-else-if="i == 4">Lugares</h5><h5 v-else>Otros</h5>
                            <div class="emojis">
                                <span v-for="(emoji, emojiName) in emojiGroup" :key="emojiName" @click="insert(emoji)" :title="emojiName">{{ emoji }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </emoji-picker>
        <Textarea @keydown.enter.exact.prevent="enviarMensaje" @keydown.enter.shift.exact.prevent="nuevaLinea"  v-model="letras" v-on:input="cambiarIcono" data-min-rows='3' class="cajaTxt" type="text" placeholder="Mensaje" :autoResize="true" rows="3" ref="focus" maxlength="3999"/>
        <!--a v-if="!c" role="button" class="btnAdj" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-paperclip fa-rotate-45"></i>
        </a-->
        <a role="button" class="btnSend" :class="{verdeActivo: this.c}" @click="enviarMensaje" :disabled="this.c">
            <i class="icon-sd-send"></i>
        </a>
        <!--div class="dropdown-menu chico">
            <a class="dropdown-item" href="#!"><i class="fas fa-robot"></i></a>
            <a class="dropdown-item" href="#!" for="mensaje">&nbsp;<i class="far fa-file-image"></i></a>
            <a class="dropdown-item" href="#!">&nbsp;<i class="fas fa-file-audio"></i></a>
            <a class="dropdown-item" href="#!"><i class="fas fa-map-marked-alt"></i></a>
        </div-->
    </div>
</div>
</template>

<script>
import EmojiPicker from 'vue-emoji-picker'
import asesorService from '../services/asesor'
import MensajeSessionHelper from '../helpers/mensajeSesion'
import Textarea from 'primevue/textarea';
export default {
    name: 'Txt',
    props: ['value'],
    components: {
        EmojiPicker,
        Textarea
    },
    created() {
        this.$bus.$off('send-mensajes')
        this.$bus.$on('send-mensajes', (senderId) => {this.senderid = senderId})
        this.$bus.$off('cuidarInput')
        this.$bus.$on('cuidarInput', () => {
            
            this.letras = MensajeSessionHelper.get(sessionStorage.sender)
            this.$refs.focus.$el.focus()
        })
    },
    data() {
        return {
            c: false,
            letras: '',
            senderid: '',
            search: '',
            display: [],
            asesorId: sessionStorage.getItem('sesion')
        }
    },
    methods: {
        cambiarIcono() {
            this.c = this.letras != '' ? true : false
            if(this.letras.length>3){
                // this.$bus.$emit('respuestas_rapidas', this.letras);
            }else{
                 this.$bus.$emit('limpiarrespuestas', this.letras);
            }   
        },
        enviarMensaje() {
            if (this.letras != '') {
                let cadena = this.letras
                this.letras = ''
                    asesorService.sendMensaje(this.senderid, cadena).then(() => {
                        this.$bus.$emit('sendresp-mensajes', this.senderid)
                        this.$bus.$emit('bajarScroll', 1)
                        this.c = false
                        this.$gtag.event('mensajesEnviados',{
                            'event_category': JSON.parse(sessionStorage.getItem('sesion')).nombre,
                            'event_label': 'Total Mensajes enviados',
                            'value': 1
                        })
                    })
                
            }
        },
        nuevaLinea(){
            if (this.letras != null){
                this.letras = `${this.letras}\n`
            }
            
        },
        insert(emoji) {
            this.letras += emoji
        },
    },
    directives: {
        focus: {
            inserted(el) {
                el.focus()
            },
        }
    },
    watch:{
        letras: function(newMensaje) {
            MensajeSessionHelper.set(sessionStorage.sender, newMensaje)
            
        }
    }

}
</script>

<style scoped>
.verde{
    color:aquamarine;
}
.gris{
    color: #b1c6d0;
    background-color: #00000000;
}
.regular-input {
    padding: 0.5rem 1rem;
    border-radius: 3px;
    border: 1px solid #ccc;
    width: 20rem;
    height: 12rem;
    outline: none;
}
.regular-input:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, .5);
}

.emoji-invoker {
    position: relative;
    right: 7px;
    bottom: 0px;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
}

.emoji-invoker:hover {
    transform: scale(1.1);
}

.emoji-invoker>svg {
    fill: #b1c6d0;
}

.emoji-picker {
    position: absolute;
    bottom: 60px;
    z-index: 1;
    font-family: 'Open Sans', sans-serif;
    border: 1px solid #ccc;
    width: 265px;
    height: 20rem;
    overflow: auto;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0.5rem;
    background: #fff;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.emoji-picker::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE and Edge */
.emoji-picker {
    -ms-overflow-style: none;
}
.emoji-picker__search {
    display: flex;
    position: sticky;
    top: 1px;
}
.emoji-picker__search>input {
    flex: 1;
    border-radius: 10rem;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    outline: none;
}

.emoji-picker h5 {
    margin-bottom: 0;
    color: #b1b1b1;
    text-transform: uppercase;
    font-size: 0.8rem;
    cursor: default;
}

.emoji-picker .emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.emoji-picker .emojis:after {
    content: "";
    flex: auto;
}

.emoji-picker .emojis span {
    padding: 0.2rem;
    cursor: pointer;
    border-radius: 5px;
}

.emoji-picker .emojis span:hover {
    background: #ececec;
    cursor: pointer;
}
</style>
